export const THEME_BASIC = "BASIC";
export const THEME_BLACK_FRIDAY = "BLACK_FRIDAY";
export const THEME_HALLOWEEN = "HALLOWEEN";
export const THEME_THANKSGIVING = "THANKSGIVING";
export const THEME_XMAS = "XMAS";
export const THEME_CYBER_MONDAY = "CYBER_MONDAY";

export const THEME_LABELS = {
  [THEME_BASIC]: "Basic",
  [THEME_BLACK_FRIDAY]: "Black Friday",
  [THEME_HALLOWEEN]: "Halloween",
  [THEME_THANKSGIVING]: "Thanksgiving",
  [THEME_XMAS]: "Christmas",
  [THEME_CYBER_MONDAY]: "Cyber Monday",
};

export const DEFAULT_THEMES_SETTINGS = [
  {
    backgroundColor: "#ffffff",
    decorator: "",
    timeColor: "#55586c",
    textColor: "#55586c",
    background: "",
    themeCode: THEME_BASIC,
    highlightColor: "#d9011a",
  },
  {
    backgroundColor: "#292933",
    decorator: "",
    timeColor: "#ffffff",
    textColor: "#ffffff",
    background: "https://cdn.iron-pop.com/themes/black-friday.png",
    themeCode: THEME_BLACK_FRIDAY,
    highlightColor: "#ffffff",
  },
  {
    backgroundColor: "#f8972b",
    decorator: "",
    timeColor: "#ffffff",
    textColor: "#ffffff",
    background: "https://cdn.iron-pop.com/themes/halloween.png",
    themeCode: THEME_HALLOWEEN,
    highlightColor: "#d9011a",
  },
  {
    backgroundColor: "#fff3d7",
    decorator: "https://cdn.iron-pop.com/themes/thanksgiving-decor.png",
    timeColor: "#b12220",
    textColor: "#b12220",
    background: "https://cdn.iron-pop.com/themes/thanksgiving.png",
    themeCode: THEME_THANKSGIVING,
    highlightColor: "#b12220",
  },
  {
    backgroundColor: "#c72814",
    decorator: "https://cdn.iron-pop.com/themes/xmas-decor.png",
    timeColor: "#ffffff",
    textColor: "#ffffff",
    background: "https://cdn.iron-pop.com/themes/xmas.png",
    themeCode: THEME_XMAS,
    highlightColor: "#ffffff",
  },
  {
    backgroundColor: "#0076da",
    decorator: "",
    timeColor: "#ffffff",
    textColor: "#ffffff",
    background: "https://cdn.iron-pop.com/themes/cyber-monday.png",
    themeCode: THEME_CYBER_MONDAY,
    highlightColor: "#ffffff",
  },
];
