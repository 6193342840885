export const ThunderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      width={10}
      height={12}
      viewBox="0 0 14 14"
      xmlSpace="preserve"
    >
      <path d="M11.919,6.228C11.827,6.085,11.669,6,11.5,6H8V0.5c0-0.236-0.165-0.44-0.396-0.489C7.369-0.039,7.139,0.082,7.043,0.297  l-4,9C2.974,9.451,2.989,9.631,3.081,9.772C3.173,9.914,3.331,10,3.5,10H7v5.5c0,0.236,0.165,0.44,0.396,0.489  C7.431,15.996,7.466,16,7.5,16c0.194,0,0.375-0.113,0.457-0.297l4-9C12.025,6.548,12.012,6.37,11.919,6.228z" />
    </svg>
  );
};
