import {
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { PluginIcon } from "../../common/components/icons";
import "./styles.css";

export default function AppStatus({ embedEnabled, enableAppEmbed }) {
  return (
    <Card>
      <div className="app-status">
        <div className="image">
          <img
            src="https://cdn.iron-pop.com/home/welcome.png"
            alt="iron-pop-welcome"
          />
        </div>
        <BlockStack gap={300}>
          <InlineStack gap={100} blockAlign="center">
            <Text variant="headingMd" as="h2">
              App status
            </Text>
            <div
              className={`circle ${
                embedEnabled ? "circle-success" : "circle-critical"
              }`}
            />
          </InlineStack>
          <div>
            {" "}
            {embedEnabled
              ? "IronPop is being integrated into your live theme and is running on your store!"
              : " IronPop hasn’t been integrated into your live theme yet, so it won’t be active on your store. Please hit “Enable IronPop” and then click “Save” in the Theme editor to make it live."}
          </div>
          <Box minHeight="500">
            <Button
              variant={!embedEnabled ? "primary" : undefined}
              onClick={() => enableAppEmbed()}
            >
              {embedEnabled ? (
                "Disable IronPop"
              ) : (
                <>
                  <PluginIcon />
                  <span>Enable IronPop</span>
                </>
              )}
            </Button>
          </Box>
        </BlockStack>
      </div>
    </Card>
  );
}
