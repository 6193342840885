/* eslint-disable react-hooks/exhaustive-deps */
import { BlockStack, Button, Card, Text } from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";
import { ChoiceList, Select, TextField } from "../../../common/components/form";
import {
  LIST_ITEM_PAGE_TRIGGER,
  SPECIFIC_URL_TYPES,
} from "../../../common/constants/sale-noti";
import { useFieldArray } from "react-hook-form";
import { useEffect } from "react";

const PageTrigger = ({ values, control }) => {
  const { settings } = values;
  // Field array for URLs
  const {
    fields: urlFields,
    append: appendURL,
    remove: removeURL,
  } = useFieldArray({
    control,
    name: "settings.specifyUrls",
  });

  // Field array for exclude URLs
  const {
    fields: excludeUrlFields,
    append: appendExcludeURL,
    remove: removeExcludeURL,
  } = useFieldArray({
    control,
    name: "settings.excludeSpecifyUrls",
  });

  const specifyUrls = settings?.specifyUrls || [{ url: null, type: "equal" }];
  const excludeSpecifyUrls = settings?.excludeSpecifyUrls || [
    { url: null, type: "equal" },
  ];

  useEffect(() => {
    if (!settings?.isPageTrigger) {
      if (specifyUrls.length <= 0) {
        appendURL({ url: null, type: "equal" });
      }
    }
  }, [settings, settings?.isPageTrigger]);

  return (
    <Card>
      <BlockStack gap={600}>
        <Text variant="headingLg" fontWeight="regular">
          Trigger
        </Text>
        <BlockStack gap="300">
          <BlockStack gap="100">
            <Text fontWeight="bold">
              Select which URL show these notifications on
            </Text>
            <Text>
              * Note: We do not support to show Sales notifications on checkout
              pages because it might decrease your checkout rate.
            </Text>
          </BlockStack>
          <ChoiceList
            name="settings.isPageTrigger"
            control={control}
            choices={LIST_ITEM_PAGE_TRIGGER}
          />
          {!settings?.isPageTrigger && (
            <BlockStack gap={200}>
              <Text fontWeight="bold">
                Show the notifications on the following URLs
              </Text>
              {/* url - Page Specify */}
              {urlFields.map((field, index) => {
                let rules = {
                  required: "This field cannot be left empty.",
                };
                const type = specifyUrls[index]?.type || "equal";
                if (["equal", "exact-equal"].includes(type)) {
                  rules = {
                    ...rules,
                    pattern: {
                      value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
                      message: "Please enter a valid URL",
                    },
                  };
                }

                return (
                  <div key={field.id} className="url-settings-row">
                    {/* Label (Select) */}
                    <Select
                      name={`settings.specifyUrls.${index}.type`}
                      control={control}
                      options={SPECIFIC_URL_TYPES}
                      {...field}
                    />

                    {/* Value (TextField) */}
                    <TextField
                      name={`settings.specifyUrls.${index}.url`}
                      control={control}
                      rules={rules}
                      validate={(value) => {
                        if (!value || value === "")
                          return "This field cannot be left empty.";
                        const urlPattern =
                          /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
                        if (rules?.pattern && !urlPattern.test(value))
                          return "Invalid format";
                        return false;
                      }}
                      placeholder={
                        SPECIFIC_URL_TYPES.find(
                          (e) => e.value === specifyUrls[index]?.type
                        )?.placeholder
                      }
                    />

                    {/* Remove Tag Button */}
                    {specifyUrls?.length > 1 && (
                      <Button
                        onClick={() => removeURL(index)}
                        variant="plain"
                        icon={DeleteIcon}
                      />
                    )}
                  </div>
                );
              })}
              <div>
                <Button
                  variant="plain"
                  onClick={() => appendURL({ url: "", type: "equal" })}
                >
                  + Add another
                </Button>
              </div>

              {/* URLs Array */}
              <div style={{ marginTop: "20px", display: "grid", gap: 16 }}>
                <Text fontWeight="bold">
                  Exclude the notifications on the following URLs
                </Text>
                <Text>Excluded URLs take priority over included ones.</Text>
                {excludeUrlFields.map((field, index) => {
                  let rules = {
                    required: "This field cannot be left empty.",
                  };
                  const type = specifyUrls[index]?.type || "equal";
                  if (["equal", "exact-equal"].includes(type)) {
                    rules = {
                      ...rules,
                      pattern: {
                        value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
                        message: "Please enter a valid URL",
                      },
                    };
                  }
                  return (
                    <div key={field.id} className="url-settings-row">
                      <Select
                        name={`settings.excludeSpecifyUrls.${index}.type`}
                        control={control}
                        options={SPECIFIC_URL_TYPES}
                        {...field}
                      />
                      {/* URL TextField */}
                      <TextField
                        name={`settings.excludeSpecifyUrls.${index}.url`}
                        control={control}
                        rules={rules}
                        validate={(value) => {
                          if (!value || value === "")
                            return "This field cannot be left empty.";
                          const urlPattern =
                            /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
                          if (rules?.pattern && !urlPattern.test(value))
                            return "Invalid format";
                          return false;
                        }}
                        placeholder={
                          SPECIFIC_URL_TYPES.find(
                            (e) => e.value === excludeSpecifyUrls[index]?.type
                          )?.placeholder
                        }
                      />

                      {/* Remove URL Button */}
                      <Button
                        onClick={() => removeExcludeURL(index)}
                        variant="plain"
                        icon={DeleteIcon}
                      />
                    </div>
                  );
                })}
                <div>
                  <Button
                    onClick={() => appendExcludeURL({ url: "", type: "equal" })}
                    variant="plain"
                  >
                    + Add rule
                  </Button>
                </div>
              </div>
            </BlockStack>
          )}
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

export default PageTrigger;
