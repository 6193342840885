import React from "react";
import { Controller } from "react-hook-form";
import { ChoiceList as PolarisChoiceList } from "@shopify/polaris";

const ChoiceList = ({ control, name, title, titleHidden, choices }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, name, onChange } }) => (
        <PolarisChoiceList
          choices={choices}
          title={title}
          titleHidden={titleHidden}
          selected={value ? ["true"] : ["false"]}
          name={name}
          onChange={(value) => {
            onChange(value[0] === "true");
          }}
        />
      )}
    />
  );
};

export default ChoiceList;
