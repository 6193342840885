/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { SaveBar, useAppBridge } from "@shopify/app-bridge-react";

import { BlockStack, Button, Page, Tabs } from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { THEME_BASIC } from "../../common/constants/design";
import SalesNotiContent from "../../features/sales-noti/sn-content";
import { getSettings } from "../../stores/slices/settingsSlice";
import salesNotiApi from "../../apis/sales-noti-api";
import SalesNotiSettings from "../../features/sales-noti/settings";
import FilteringRules from "../../features/sales-noti/filtering-rules";
import "./styles.css";

export default function SalesNoti() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const shopify = useAppBridge();

  const shop = useSelector((state) => state.shop);
  const designSettings = useSelector((state) => state.settings);

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(
    location?.hash === "#settings" ? 1 : 0
  );
  const [originSettings, setOriginSettings] = useState(null);

  useEffect(() => {
    const fetchSalesNoti = async () => {
      setLoading(true);
      const response = await salesNotiApi.getSalesNoti(shop.shop_uuid);

      if (response.data.settings && response.data.settings.importedFiles) {
        response.data.settings.originImportedFiles =
          response.data.settings.importedFiles;
      }

      const data = _.pick(response.data, ["content", "settings"]);
      setOriginSettings(data);

      reset(data);
      setLoading(false);
    };

    fetchSalesNoti();
    if (!designSettings.loaded) {
      dispatch(getSettings(shop.shop_uuid));
    }
  }, []);

  const {
    data: { settingPopups = {} },
  } = designSettings;
  const design = settingPopups.uiThemeSettings || {};
  const watermark = settingPopups.brandingSettings?.watermark || true;
  const showBrand = watermark && design.themeCode === THEME_BASIC;

  // handle change tab
  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
    navigate(`/pop-types/sales-noti?tab=${selectedTabIndex}`, {
      replace: true,
    });
  }, []);

  // Update the active tab if the hash changes
  useEffect(() => {
    const queries = new URLSearchParams(window.location.search);
    let currentTab = queries.get("tab") || 0;
    const tabIndex = parseInt(currentTab);
    setSelected(tabIndex >= 0 ? tabIndex : 0);
  }, [window.location.search]);

  const defaultValues = {
    content: {
      titleText: "{{first_name}} in {{country}} purchased",
      productNameText: "{{product_link}}, {{product_title}}",
      productName: "MEN COTTON BAG",
      timeAgo: "4 minutes ago",
      enableTimeAgo: true,
    },
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
    reset,
    watch,
  } = useForm({ defaultValues });
  const { content, settings } = watch();
  const handleSalesNotiResetDefaultContent = () => {
    setValue("content.titleText", defaultValues.content.titleText);
    setValue("content.productNameText", defaultValues.content.productNameText);
    setValue("content.enableTimeAgo", defaultValues.content.enableTimeAgo);
  };

  const tabs = [
    {
      id: "content",
      content: "Pop content",
      panelID: "pop-content",
    },
    {
      id: "settings",
      content: "Settings",
      panelID: "pop-settings",
    },
    {
      id: "filtering-rules",
      content: "Filtering rules",
      panelID: "filtering-rules",
    },
  ];
  const tabContent = [
    <SalesNotiContent
      values={{ content, design, showBrand }}
      control={control}
      resetDefault={handleSalesNotiResetDefaultContent}
    />,
    <div className="sale-noti-settings">
      <SalesNotiSettings
        setValue={setValue}
        originSettings={originSettings}
        values={{ settings, shopuuid: shop.shop_uuid }}
        control={control}
      />
    </div>,
    <div>
      <FilteringRules
        values={{ settings, shop }}
        control={control}
        setValue={setValue}
      />
    </div>,
  ];

  const onSubmit = () => {
    if (!isValid) return;
    if (content.titleText === "")
      content.titleText = defaultValues.content.titleText;
    if (content.productNameText === "")
      content.productNameText = defaultValues.content.productNameText;
    const payload = { content, settings };

    const updateSalesNoti = async () => {
      setLoading(true);
      await salesNotiApi.updateSalesNoti(shop.shop_uuid, payload);
      reset(payload);
      setLoading(false);
      setOriginSettings(payload);
      shopify.toast.show("Settings updated successfully!");
    };

    updateSalesNoti();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        backAction={{
          onAction: () => {
            navigate("/pop-types");
          },
        }}
        title="Sales Notification"
        primaryAction={
          <Button
            variant="primary"
            size="large"
            submit
            loading={loading}
            disabled={!isDirty}
          >
            Save
          </Button>
        }
      >
        <div className="IronPop-salesNoti">
          <SaveBar open={isDirty && isValid} id="sales-noti-save-bar">
            <button
              type="submit"
              variant="primary"
              loading={loading}
              disabled={loading}
            ></button>
            <button
              type="button"
              disabled={loading}
              onClick={() => reset()}
            ></button>
          </SaveBar>
          <BlockStack gap={400}>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              {tabContent[selected]}
            </Tabs>
          </BlockStack>
        </div>
      </Page>
    </form>
  );
}
