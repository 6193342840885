/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BlockStack } from "@shopify/polaris";

import DataSource from "./data-source";
import PageTrigger from "./page-trigger";
import DisplayOptions from "./display-options";

import "../styles.css";

const SalesNotiSettings = ({ values, control, setValue, originSettings }) => {
  return (
    <BlockStack gap={600}>
      {/* Page trigger */}
      <PageTrigger values={values} control={control} />
      {/* Data source */}
      <DataSource
        values={values}
        control={control}
        setValue={setValue}
        originSettings={originSettings}
      />
      {/* Display options */}
      <DisplayOptions control={control} />
    </BlockStack>
  );
};

export default SalesNotiSettings;
