import React from "react";
import { BlockStack, Button, Card, InlineStack, Text } from "@shopify/polaris";
import "./styles.css";
import ColorPicker from "../../common/components/form/ColorPicker";
import TextField from "../../common/components/form/TextField";
import SalesNotiPreview from "../../common/components/previews/sale-noti";
import OrderCountPreview from "../../common/components/previews/order-count";

const ThemeSettings = ({
  control,
  setValue,
  watermark,
  settings,
  handleReset,
  shop,
}) => {
  const shopType = shop?.shopPlan?.shopType || "NEW";

  return (
    <div className="theme-settings-form">
      <Card>
        <div className="theme-settings">
          <BlockStack gap="400">
            <Text as="h2" variant="headingLg" fontWeight="regular">
              Theme settings
            </Text>
            <InlineStack gap={500} align="space-between" blockAlign="center">
              <Text as="h2">Background color</Text>
              <ColorPicker
                control={control}
                name="backgroundColor"
                setValue={setValue}
              />
            </InlineStack>
            <InlineStack gap={500} align="space-between" blockAlign="center">
              <Text as="h2">Time ago color</Text>
              <ColorPicker
                control={control}
                name="timeColor"
                setValue={setValue}
              />
            </InlineStack>
            <InlineStack gap={500} align="space-between" blockAlign="center">
              <Text as="h2">Main text color</Text>
              <ColorPicker
                control={control}
                name="textColor"
                setValue={setValue}
              />
            </InlineStack>
            <InlineStack gap={500} align="space-between" blockAlign="center">
              <Text as="h2">Highlight color</Text>
              <ColorPicker
                control={control}
                name="highlightColor"
                setValue={setValue}
              />
            </InlineStack>
            <InlineStack gap={500} align="space-between" blockAlign="center">
              <Text as="h2">Border radius</Text>
              <div style={{ width: 147 }}>
                <TextField name="borderRadius" label="" control={control} />
              </div>
            </InlineStack>
            <div style={{ marginTop: 20 }}>
              <Button onClick={handleReset} variant="plain">
                Reset to default
              </Button>
            </div>
          </BlockStack>
          <BlockStack gap={600}>
            <Text as="h2" variant="headingLg" fontWeight="regular">
              Preview
            </Text>
            <BlockStack gap={300}>
              <Text>Sales notification: </Text>
              <SalesNotiPreview
                showBrand={watermark && shopType === "PU"}
                settings={settings}
              />
            </BlockStack>
            <BlockStack gap={300}>
              <Text>Order count:</Text>
              <OrderCountPreview
                settings={{ lookback: 2, format: "hours" }}
                ui={settings}
                content={{
                  firstLine: "{{orders}}",
                  secondLine: "in last {{time}}",
                }}
              />
            </BlockStack>
          </BlockStack>
        </div>
      </Card>
    </div>
  );
};

export default ThemeSettings;
