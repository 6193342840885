import {
  Card,
  Text,
  BlockStack,
  Button,
  TextField,
  Tag,
  InlineStack,
} from "@shopify/polaris";
import { useState } from "react";
import { XIcon } from "@shopify/polaris-icons";
import IronPopToggle from "../../../common/components/custom/toggle";
import SelectProductModal from "../../../common/components/modal/product/ProductModal";
import { useFieldArray } from "react-hook-form";

const FilteringRules = ({ control, values, setValue }) => {
  const { shop, settings } = values;
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const {
    fields: tagFields,
    append: appendTag,
    remove: removeTag,
  } = useFieldArray({
    control,
    name: "settings.filteringRules.excludeTags",
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault();
      appendTag(inputValue.trim());
      setInputValue("");
    }
  };

  const isExcludeOutOfStocks =
    settings?.filteringRules?.excludeOutOfStocks ?? true;
  const selectedTags = settings?.filteringRules?.excludeTags
    ? settings?.filteringRules?.excludeTags
    : [];

  const selectedProducts = settings?.filteringRules?.excludeProducts
    ? settings?.filteringRules?.excludeProducts
    : [];

  const handleEnableOutOfStock = (enabled) => {
    setValue("settings.filteringRules.excludeOutOfStocks", enabled, {
      shouldDirty: true,
    });
  };

  const handleChangeSelectedProducts = (values) => {
    setValue("settings.filteringRules.excludeProducts", values, {
      shouldDirty: true,
    });
  };

  const onRemoveProduct = (id) => {
    const _selectedProducts = selectedProducts.filter((p) => p.id !== id);
    setValue("settings.filteringRules.excludeProducts", _selectedProducts, {
      shouldDirty: true,
    });
  };

  return (
    <div className="filtering-rules">
      <BlockStack gap="1000">
        <div className="section">
          <BlockStack gap={400}>
            <Text fontWeight="bold">Exclude out-of-stock products</Text>
            <Text>
              All out-of-stock products will not appear in the sales
              notifications
            </Text>
          </BlockStack>

          <div>
            <Card>
              <IronPopToggle
                defaultChecked={isExcludeOutOfStocks}
                checkedLabel={
                  <p>
                    Enable <b>“Exclude out-of-stock products”</b>
                  </p>
                }
                uncheckedLabel={
                  <p>
                    Enable <b>“Exclude out-of-stock products”</b>
                  </p>
                }
                labelSuffix={true}
                onChange={(checked) => {
                  handleEnableOutOfStock(!checked);
                }}
              />
            </Card>
          </div>
        </div>
        <div className="section">
          <BlockStack gap={200}>
            <Text fontWeight="bold">Exclude products with tags</Text>
            <Text>Exclude products that contain the entered tags.</Text>
          </BlockStack>
          <Card>
            <BlockStack align="center" gap={200}>
              <Text fontWeight="bold">Tags</Text>
              <div onKeyDown={handleKeyDown}>
                <TextField
                  value={inputValue}
                  onChange={setInputValue}
                  placeholder="Type a tag and press Enter"
                />
              </div>
              <div>
                <InlineStack spacing="tight" gap={100}>
                  {tagFields.map((field, index) => (
                    <Tag key={field.id} onRemove={() => removeTag(index)}>
                      {selectedTags[index]}
                    </Tag>
                  ))}
                </InlineStack>
              </div>
            </BlockStack>
          </Card>
        </div>
        <div className="section">
          <BlockStack gap={200}>
            <Text fontWeight="bold">Exclude products</Text>
            <Text>
              Exclude test products or products with poor reviews or low
              conversion rates.
            </Text>
          </BlockStack>

          <Card>
            <div className="heading">
              <Text fontWeight="bold">{`Products ${selectedProducts.length}`}</Text>
              <div className="add" onClick={() => setShowModal(true)}>
                + Add products
              </div>
            </div>
            {selectedProducts.length <= 0 ? (
              <div className="no-product">No products</div>
            ) : (
              <div className="selected-products">
                {selectedProducts.map((product, index) => {
                  const { image, title = "", id } = product;
                  return (
                    <div className="item" key={`selected-${id}`}>
                      <div>{`${index + 1}.`}</div>
                      <div className={`${image ? "" : "no-img"}`}>
                        <img
                          src={
                            image
                              ? image
                              : "https://cdn.iron-pop.com/common/no-img.jpg"
                          }
                          alt={`image-${id}`}
                        />
                      </div>
                      <div>{title}</div>
                      <Button
                        icon={XIcon}
                        variant="plain"
                        onClick={() => onRemoveProduct(id)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </Card>
        </div>
      </BlockStack>
      {showModal && (
        <SelectProductModal
          selectedProducts={selectedProducts}
          onChange={(values) => {
            handleChangeSelectedProducts(values);
          }}
          shop={shop}
          active={showModal}
          closeModal={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default FilteringRules;
