import {
  BlockStack,
  Card,
  InlineGrid,
  InlineStack,
  Link,
  Text,
} from "@shopify/polaris";
import { DocGuideIcon, MailPlusIcon } from "../../common/components/icons";
import "./styles.css";

const Support = () => {
  return (
    <Card>
      <BlockStack gap={300}>
        <Text variant="headingMd" as="h2">
          Need any help?
        </Text>
        <InlineGrid gap={600} columns={2}>
          <div className="support-item">
            <BlockStack gap={300}>
              <InlineStack gap={200}>
                <MailPlusIcon />
                <Link
                  target="_blank"
                  monochrome
                  url="https://mail.google.com/mail/?view=cm&fs=1&to=support@ironpop.io"
                >
                  <Text variant="headingSm" as="h3">
                    Get email support
                  </Text>
                </Link>
              </InlineStack>
              <p>
                Email us at <b>support@ironpop.io</b>. We’ll get back to you as
                soon as possible.
              </p>
            </BlockStack>
          </div>
          <div className="support-item">
            <BlockStack gap={200}>
              <InlineStack gap={200}>
                <DocGuideIcon />
                <Link
                  target="_blank"
                  monochrome
                  url="https://ironpop.io/sales-popups.html"
                >
                  <Text variant="headingSm" as="h3">
                    Knowledge base
                  </Text>
                </Link>
              </InlineStack>
              <p>
                Find a solution for your problem with documents and tutorials.
              </p>
            </BlockStack>
          </div>
        </InlineGrid>
      </BlockStack>
    </Card>
  );
};

export default Support;
