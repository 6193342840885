import { request } from "./base-api";
import { downloadFile } from "../common/helper";

const salesNotiApi = {
  getSalesNoti: (shopuuid) => {
    return request("admin-api.sales-noti.get-sales-noti", {
      params: { shopuuid },
    });
  },
  updateSalesNoti: (shopuuid, payload) => {
    return request("admin-api.sales-noti.update-sales-noti", {
      params: { shopuuid },
      body: payload,
    });
  },
  importOrders: (shopuuid, payload) => {
    return request("admin-api.sales-noti.import-orders", {
      params: { shopuuid },
      body: payload,
    });
  },
  exportOrdersToCSV: async (shopuuid, payload) => {
    const res = await request(
      "admin-api.sales-noti.export-orders-csv",
      {
        params: { shopuuid },
        body: payload,
      },
      "blob"
    );

    const blob = new Blob([res.data], { type: 'text/csv' });
    downloadFile(blob, payload.file_name || "orders.csv");
    // const blob = await res.blob();
    return res;
  },
};

export default salesNotiApi;
