/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { SaveBar } from '@shopify/app-bridge-react';
import { BlockStack, Button, Page, Tabs } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import './styles.css';
import OrderCountContent from '../../features/order-count/oc-content';
import OrderCountSettings from '../../features/order-count/oc-settings';
import { getSettings } from '../../stores/slices/settingsSlice';
import orderCountApi from '../../apis/order-count-api';

export default function OrderCount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const shop = useSelector((state) => state.shop);
  const designSettings = useSelector((state) => state.settings);
  const design = _.get(designSettings, 'data.settingPopups.uiThemeSettings', {});

  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [selected, setSelected] = useState(location?.hash === '#settings' ? 1 : 0);
  const [orderCountSettings, setOrderCountSettings] = useState({ content: {}, settings: {} });

  useEffect(() => {
    const fetchOrderCount = async () => {
      setLoading(true);
      const response = await orderCountApi.getOrderCount(shop.shop_uuid);
      const data = _.pick(response.data, ['content', 'settings']);
      setOrderCountSettings(data);
      reset(data);
      setLoading(false);
    };

    fetchOrderCount();

    if (!designSettings.loaded) {
      dispatch(getSettings(shop.shop_uuid));
    }
  }, []);

  // handle change tab
  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
    const hash = selectedTabIndex === 1 ? '#settings' : '#content';
    navigate(`/pop-types/order-count${hash}`, { replace: true });
  }, []);

  // Update the active tab if the hash changes
  useEffect(() => {
    if (location?.hash === '#settings') {
      setSelected(1);
    } else {
      setSelected(0);
    }
  }, [location?.hash]);

  const defaultValues = {
    content: { firstLine: '{{orders}}', secondLine: 'in last {{time}}' },
    settings: {
      lookback: 2,
      format: 'day',
      smallOrders: 1,
      hideSmallOrders: true,
      showOnMobile: false,
    },
  };

  const { handleSubmit, control, setValue, reset, watch } = useForm({ defaultValues });

  const { content, settings } = watch();

  useEffect(() => {
    if (isNaN(settings.lookback) || settings.lookback < 1 || isNaN(settings.smallOrders) || settings.smallOrders < 1) {
      setErrorForm(true);
    } else setErrorForm(false);
  }, [settings.lookback, settings.smallOrders]);

  const handleResetDefaultContent = () => {
    setValue('content.firstLine', defaultValues.content.firstLine);
    setValue('content.secondLine', defaultValues.content.secondLine);
  };

  const tabs = [
    {
      id: 'content',
      content: 'Pop content',
      accessibilityLabel: 'All customers',
      panelID: 'pop-content',
    },
    {
      id: 'settings',
      content: 'Settings',
      panelID: 'pop-settings',
    },
  ];

  const tabContent = [
    <OrderCountContent
      values={{ settings, content, design }}
      control={control}
      resetDefault={handleResetDefaultContent}
    />,
    <OrderCountSettings values={{ settings, content, design }} control={control} />,
  ];

  const onSubmit = () => {
    if (content.firstLine === '') content.firstLine = defaultValues.content.firstLine;
    if (content.secondLine === '') content.secondLine = defaultValues.content.secondLine;

    const payload = { content, settings };

    const updateOrderCount = async () => {
      setLoading(true);
      await orderCountApi.updateOrderCount(shop.shop_uuid, payload);
      reset(payload);
      setOrderCountSettings(payload);
      setLoading(false);
    };

    updateOrderCount();
  };

  const isFormDirty = () => {
    if (_.isEmpty(orderCountSettings.content)) return false;

    const contentKeys = Object.keys(content);
    for (const key of contentKeys) {
      if (String(content[key]) !== String(orderCountSettings.content[key])) return true;
    }

    const settingsKeys = Object.keys(settings);
    for (const key of settingsKeys) {
      if (String(settings[key]) !== String(orderCountSettings.settings[key])) return true;
    }

    return false;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        title="Order count"
        backAction={{
          onAction: () => {
            navigate('/pop-types');
          },
        }}
        primaryAction={
          <Button variant="primary" size="large" submit loading={loading} disabled={!isFormDirty() || errorForm}>
            Save
          </Button>
        }
      >
        <div className="IronPop-orderCount">
          <SaveBar open={isFormDirty() && !errorForm} id="order-count-save-bar">
            <button type="submit" variant="primary" disabled={loading}></button>
            <button type="button" disabled={loading} onClick={() => reset()}></button>
          </SaveBar>
          <BlockStack gap={400}>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <BlockStack gap={400}>
                <p style={{ padding: '0 4px' }}>
                  Build trust and credibility by letting customers know how many people have checked out on your store,
                  which helps to decrease the abandonment rate. This notification shows at cart page.
                </p>
                {tabContent[selected]}
              </BlockStack>
            </Tabs>
          </BlockStack>
        </div>
      </Page>
    </form>
  );
}
