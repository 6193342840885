import React, { useEffect, useState } from "react";
import { Button, Link, Tooltip } from "@shopify/polaris";
import NotifyModal from "./notify-modal";

const RequestPowerUser = ({
  shopType,
  shopEmail,
  reviewShopTypeStatus,
  triggerPowerUser,
  loading,
}) => {
  const [isRequest, setIsRequest] = useState(false);
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (reviewShopTypeStatus === "WAITING") {
      setActive(true);
    }
  }, [reviewShopTypeStatus]);

  return shopType !== "PU" ? (
    <>
      <p>
        This feature is exclusive to Power Users! Upgrade for FREE today and
        unlock all the special offers waiting just for you!
      </p>
      <div className="actions">
        <Button
          onClick={() => {
            triggerPowerUser();
            setIsRequest(true);
          }}
          variant="primary"
          disabled={reviewShopTypeStatus === "WAITING"}
          loading={loading}
        >
          Become a POWER USER
        </Button>
        <Tooltip
          content={
            <div>
              Offers included:
              <ul>
                <li>5 festive themes</li>
                <li>Removal of Pop's branding</li>
              </ul>
            </div>
          }
        >
          <Link>What's included?</Link>
        </Tooltip>
      </div>

      <NotifyModal
        email={shopEmail}
        active={active && isRequest}
        onClose={() => setActive(false)}
      />
    </>
  ) : (
    <></>
  );
};

export default RequestPowerUser;
