/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { THEME_BASIC, THEME_THANKSGIVING, THEME_XMAS } from '../../constants/design';
import { ThunderIcon } from '../icons';

const SalesNotiPreview = ({
  content = {
    productName: 'MEN COTTON BAG',
    timeAgo: '4 minutes ago',
    enableTimeAgo: true,
  },
  settings,
  showBrand = false,
  size = 'normal',
}) => {
  const { productName, timeAgo, enableTimeAgo } = content;

  let processParsedContent = 'Someone in somewhere purchased';
  if (content?.titleText) {
    let titleText = content.titleText.replaceAll('{{first_name}}', 'Someone');
    titleText = titleText.replaceAll('{{last_name}}', 'Someone');
    titleText = titleText.replaceAll('{{full_name}}', 'Someone');
    titleText = titleText.replaceAll('{{city}}', 'somewhere');
    titleText = titleText.replaceAll('{{country}}', 'somewhere');
    //Check the duplicate words
    processParsedContent = titleText.replace(/\b(\w+)\s+\1\b/g, '$1');
  }

  let stylePopup = {
    color: settings?.textColor,
    borderRadius: settings?.borderRadius || (size === 'normal' ? 5 : 3),
    zIndex: '10',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '360px',
    paddingRight: 2,
    minWidth: size === 'normal' ? '320px' : '270px',
  };

  let styleImg = {
    objectFit: 'cover',
    maxHeight: '75px',
    objectPosition: 'center center',
    height: '100%',
    margin: '0 6px 0 0',
  };

  if (settings?.themeCode && settings?.themeCode !== THEME_BASIC) {
    stylePopup = {
      ...stylePopup,
      width: size === 'normal' ? 'unset' : '270px',
    };

    styleImg = {
      ...styleImg,
      maxWidth: size === 'normal' ? 65 : 62,
      borderRadius: size === 'normal' ? 5 : 3,
      margin: '6px',
    };
  }

  if (settings?.background) {
    stylePopup.backgroundImage = `url(${settings?.background})`;
    stylePopup.backgroundRepeat = 'no-repeat';
    stylePopup.backgroundSize = '100% 100%';
    stylePopup.backgroundPositionY = 'bottom';
  } else {
    stylePopup.backgroundColor = settings?.backgroundColor;
    stylePopup.boxShadow = '1px 1px 5px #F2F2F2';
    stylePopup.border = '1px solid rgb(239 239 239)';
  }

  const themeDecorateStyleMapping = {
    [THEME_THANKSGIVING]: {
      position: 'absolute',
      right: 0,
      top: size === 'normal' ? '-40%' : '-28%',
      zIndex: 5,
      width: '48%',
      height: 'auto',
    },
    [THEME_XMAS]: {
      position: 'absolute',
      right: 0,
      top: '-39%',
      zIndex: 5,
      width: '20%',
      height: 'auto',
    },
  };

  const SalesNotiPreviewComponent = useMemo(() => {
    return (
      <div
        id="custom-popup-view"
        style={{
          position: 'relative',
          maxWidth: size !== 'normal' ? '268px' : '360px',
          minWidth: size !== 'normal' ? '268px' : 'unset',
        }}
      >
        {settings?.decorator && settings?.decorator !== '' && (
          <img style={themeDecorateStyleMapping[settings?.themeCode]} src={settings?.decorator} alt="decorator-popup" />
        )}
        <div className="iron-popup-view" style={stylePopup}>
          <img
            src="https://collectivememory.vn/wp-content/uploads/2023/03/Large-Tote-Bag-for-Men-Oliver-Green-Color-1.jpg"
            alt="Product Image"
            style={styleImg}
          />
          <div style={{ flexGrow: 1, padding: '6px' }}>
            <p style={{ margin: 0, fontSize: size === 'normal' ? 12 : 10 }}>{processParsedContent}</p>
            <p
              style={{
                margin: 0,
                fontSize: size === 'normal' ? 14 : 12,
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '240px',
              }}
            >
              {productName}
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {enableTimeAgo && (
                <p
                  style={{
                    margin: 0,
                    fontSize: size === 'normal' ? 12 : 10,
                    color: settings?.timeColor,
                  }}
                >
                  {timeAgo}
                </p>
              )}
              {showBrand && (
                <div
                  className="brand-text"
                  style={{
                    color: settings?.timeColor,
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 2,
                    gap: 2,
                    fontSize: size === 'normal' ? 12 : 10,
                  }}
                >
                  <ThunderIcon />
                  <span>by</span>
                  <span
                    style={{
                      fontWeight: '500',
                      textDecoration: 'underline',
                      color: settings?.timeColor,
                      cursor: 'pointer',
                    }}
                  >
                    IronPop
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          id="close-popup"
          style={{
            background: 'none',
            border: 'none',
            display: 'flex',
            alignItems: 'top',
            fontSize: size === 'normal' ? 16 : 14,
            cursor: 'pointer',
            position: 'absolute',
            top: 8,
            right: 0,
            color: settings?.textColor,
            paddingRight: 8,
          }}
        >
          ×
        </button>
      </div>
    );
  }, [settings, showBrand, content]);

  return SalesNotiPreviewComponent;
};

export default SalesNotiPreview;
