import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import shopApi from "../../apis/shop-api";

const initialState = { connected: false, authToken: "", loading: false };

export const getShopInfo = createAsyncThunk(
  "shop/get-info",
  async (queries) => {
    const response = await shopApi.shopInfo(queries);
    return response.data;
  }
);

export const installShop = createAsyncThunk("shop/install", async (queries) => {
  const response = await shopApi.install(queries);
  return response.data;
});

export const triggerPU = createAsyncThunk(
  "shop/pu-trigger",
  async ({ params, body }) => {
    const response = await shopApi.puTrigger(params, body);
    return response.data;
  }
);

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Shop info
    builder.addCase(getShopInfo.fulfilled, (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        ...payload.shopData,
        authToken: payload.authToken,
        connected: true,
      };
    });
    builder.addCase(getShopInfo.rejected, (state, action) => {
      state.connected = false;
      const error = action.error;
      console.log("error: ", error);
    });

    // Install shop
    builder.addCase(installShop.fulfilled, (state, action) => {
      const payload = action.payload;
      return { ...state, ...payload, id: payload.shop };
    });
    builder.addCase(installShop.rejected, (state, action) => {
      const error = action.error;
      console.log("error: ", error);
    });

    // Trigger power user
    builder.addCase(triggerPU.fulfilled, (state, action) => {
      const payload = action.payload;
      state.loading = false;
      if (payload.status) {
        state.shopPlan = { ...state.shopPlan, reviewShopTypeStatus: "WAITING" };
      }
    });
    builder.addCase(triggerPU.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(triggerPU.rejected, (state, action) => {
      const error = action.error;
      state.loading = false;
      console.log("error: ", error);
    });
  },
});

export default shopSlice.reducer;
