export const CheckCircleFillIcon = () => {
  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="21px"
      height="21px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1 0 0 1 -438 -637 )">
        <path
          d="M 10.5 0  C 16.29898987322333 0  21 4.701010126776668  21 10.5  C 21 16.29898987322333  16.29898987322333 21  10.5 21  C 4.701010126776668 21  0 16.29898987322333  0 10.5  C 0 4.701010126776668  4.701010126776668 0  10.5 0  Z M 15.7906875 6.523124999999999  L 15.789375 6.523125000000001  C 15.604868792626055 6.339262752666601  15.355010862311715 6.236023980320284  15.094534632006523 6.236023980320284  C 14.820137141649695 6.236023980320284  14.55819322502293 6.350556455207845  14.371874999999998 6.552000000000001  L 9.8135625 12.3598125  L 7.0665000000000004 9.6114375  C 6.884286701781626 9.441649378318628  6.644487235020325 9.347242126600696  6.395429626600694 9.347242126600696  C 5.851774325986007 9.347242126600696  5.411054626600694 9.787961825986008  5.411054626600694 10.331617126600696  C 5.411054626600694 10.580674735020326  5.505461878318628 10.820474201781627  5.67525 11.0026875  L 9.148125 14.476875  C 9.332679259810337 14.66109305414066  9.582786847789722 14.764557463153857  9.843548360067729 14.764557463153857  C 10.116976285994511 14.764557463153857  10.378084982739878 14.650830250909545  10.5643125 14.450625  L 15.803812500000003 7.901249999999999  C 16.17537852990508 7.514918458495238  16.1695441603836 6.902309658740108  15.7906875 6.523124999999999  Z "
          fillRule="nonzero"
          fill="#000000"
          stroke="none"
          transform="matrix(1 0 0 1 438 637 )"
        />
      </g>
    </svg>
  );
};
