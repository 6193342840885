import React from "react";
import { Card, InlineStack, Text } from "@shopify/polaris";
import "./styles.css";
import RequestPowerUser from "../power-user/request-pu";
import SalesNotiPreview from "../../common/components/previews/sale-noti";
import Toggle from "../../common/components/custom/toggle";

const PowerUserSettings = ({
  shop,
  watermark,
  triggerPowerUser,
  updateWatermark,
}) => {
  const { loading, shopPlan = {}, shopInfo = {} } = shop;
  const { shopType = "", reviewShopTypeStatus = "" } = shopPlan;

  const { email = "", customer_email = "" } = shopInfo;
  const shopEmail = email || customer_email || null;

  return (
    <div className="power-user">
      <Card>
        <InlineStack gap={200} blockAlign="center">
          <Text variant="headingMd">Pop’s Branding</Text>
          <img
            alt="ip-crown-icon"
            src="https://cdn.iron-pop.com/common/crown-icon.png"
            width={20}
            height={20}
          />
        </InlineStack>
        <SalesNotiPreview showBrand={watermark} />
        <Toggle
          disabled={shopType !== "PU"}
          defaultChecked={watermark}
          checkedLabel="Show “Powered by IronPop” label"
          labelSuffix={true}
          onChange={updateWatermark}
        />
        <RequestPowerUser
          shopType={shopType}
          shopEmail={shopEmail}
          reviewShopTypeStatus={reviewShopTypeStatus}
          loading={loading}
          triggerPowerUser={triggerPowerUser}
        />
      </Card>
    </div>
  );
};

export default PowerUserSettings;
