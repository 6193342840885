export const POP_TYPES_PROPERTIES = [
  {
    image: "https://cdn.iron-pop.com/pop-types/sales-pop-img.png",
    title: "Sales notifications",
    key: "salesNoti",
    content:
      "Boost credibility and sales by displaying recent purchases. Show customers what others are buying to create a sense of a busy store, build trust, and encourage hesitant shoppers. Customizable to match your store's style.",
    primaryAction: "Configure",
    primaryActionUrl: "/pop-types/sales-noti",
    primaryActionStatus: "active",
    secondaryAction: "",
    toggle: true,
  },
  {
    image: "https://cdn.iron-pop.com/pop-types/order-count-img.png",
    title: "Order count",
    key: "orderCount",
    content:
      "Display the number of completed purchases in the last 24 hours. Motivate new visitors to buy by showcasing your store's recent activity. Create urgency and boost conversions by demonstrating real-time popularity.",
    primaryAction: "Configure",
    primaryActionUrl: "/pop-types/order-count",
    primaryActionStatus: "active",
    secondaryAction: "",
    toggle: true,
  },
  {
    image: "https://cdn.iron-pop.com/pop-types/reel-pop-img.png",
    title: "Reel Pops",
    key: "reelPops",
    content:
      "Capture visitors' attention by showcasing autoplay reels as pop-ups. Use hovering videos on the product detail page (PDP) to engage users and encourage them to make a purchase.",
    primaryAction: "Coming soon",
    primaryActionUrl: "",
    primaryActionStatus: "disable",
    secondaryAction: "",
    toggle: false,
  },
  {
    image: null,
    title: "Add-to-cart Pops",
    key: "addToCart",
    content:
      "Display real-time notifications when shoppers add items to their carts. Sync directly with your Shopify store's Add to Cart events to show live shopping activity. Boost social proof and create a sense of demand, encouraging more visitors to make purchases.",
    primaryAction: "Coming soon",
    primaryActionUrl: "",
    primaryActionStatus: "disable",
    secondaryAction: "",
    toggle: false,
  },
  {
    image: null,
    title: "Product page live view",
    key: "liveView",
    content:
      "Showcase your product popularity in real-time. Watch your website come alive with dynamic engagement metrics. Encourage purchases by displaying live popularity data to visitors.",
    primaryAction: "Coming soon",
    primaryActionUrl: "",
    primaryActionStatus: "disable",
    secondaryAction: "",
    toggle: false,
  },
];