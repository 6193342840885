import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox as PolarisCheckbox } from "@shopify/polaris";

const Checkbox = ({ control, name, label, options }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...data }, fieldState: { error } }) => (
        <PolarisCheckbox
          {...data}
          checked={data.value}
          label={label}
          options={options}
          error={error && error.message}
        />
      )}
    />
  );
};

export default Checkbox;
