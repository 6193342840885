/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Modal, Icon, TextField, Spinner } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";

import ListOptions from "./list-options";
import { unionBy, prettyText } from "../../../utils";
import "./style.css";
import productApi from "../../../../apis/product";

const NUMBER_PRODUCT_PER_PAGE = 20;
// DONE
const SelectProductModal = (props) => {
  let timerTyping = undefined;
  const timerRef = useRef(null);

  const { shop, selectedProducts, onChange, active, closeModal } = props;
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [skip, setSkip] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [endCursor, setEndCursor] = useState(null);

  /**
   * Get product data
   */
  const getList = async (q = "") => {
    let query = {
      shop: shop?.id,
      title: encodeURIComponent(q),
      next: hasNextPage,
      limit: NUMBER_PRODUCT_PER_PAGE,
    };

    if (endCursor && skip > 0) {
      query = {
        ...query,
        cursor: endCursor,
      };
    }

    const productData = await productApi.getProducts(shop?.shop_uuid, query);
    const products = productData?.data?.data || [];
    const pageInfo = productData?.data?.pageInfo || {};
    if (pageInfo && pageInfo.endCursor) {
      setEndCursor(pageInfo.endCursor);
    }
    setHasNextPage(pageInfo?.hasNextPage ?? false);

    return products;
  };

  /**
   * Union product list by id and variant id
   * @param {*} currentList
   * @param {*} newList
   * @returns
   */
  const unionProducts = (currentList, newList) => {
    const unionList = currentList;
    newList.forEach((ele) => {
      const { id } = ele;
      const index = unionList.findIndex((e) => e.id === id);
      if (index === -1) {
        // New element
        unionList.push(ele);
      } else {
        // Update element
        currentList[index] = ele;
      }
    });

    return unionList;
  };

  const handleClose = () => {
    setOptions([]);
    setSelectedOptions([]);
    setSkip(0);
    closeModal();
  };

  // handle add product
  const handleAddProducts = () => {
    // Build new product list from list of selected product, keep order of old products and add new products in the bottom
    // Get general items in selectedProducts and selectedOptions
    let newSelectedProducts = selectedProducts.filter(
      (item) => selectedOptions.findIndex((p) => p.id === item.id) >= 0
    );
    // Add new items from product list
    newSelectedProducts = unionBy([newSelectedProducts, selectedOptions], "id");
    onChange(newSelectedProducts);
    handleClose();
  };

  // Key press event on search field
  const handleKeyDown = (event) => {
    // Clear timer on key down to prevent API calls
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  // Key up event, start searching after 2000ms
  const handleKeyUp = (event) => {
    let value = inputValue.toLowerCase().trim();
    value = prettyText(value);
    setSkip(0);
    // Invoke search request after 2000ms
    // Clear any previous timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer for API call
    timerRef.current = setTimeout(async () => {
      setLoading(true);
      setOptions([]);
      const data = await getList(value);
      setList(data);
      setLoading(false);
    }, 1000);
  };

  const onTextChange = (value) => {
    setInputValue(value);
  };

  const onSelect = (index) => {
    const isSelected = options[index].isSelected;
    let _selectedOptions = structuredClone(selectedOptions);
    if (isSelected) {
      const { id } = options[index];
      _selectedOptions = _selectedOptions.filter((item) => item.id !== id);
    } else {
      _selectedOptions.push(options[index]);
    }

    const _options = structuredClone(options);

    _options[index].isSelected = !options[index].isSelected;

    setOptions(_options);
    setSelectedOptions(_selectedOptions);
  };

  // Read more items
  const loadMore = async () => {
    if (loading) return;
    const nextSkip = skip + NUMBER_PRODUCT_PER_PAGE;
    // Set skip, limit for next page
    setSkip(nextSkip);
    setLoading(true);
    let value = inputValue.toLowerCase().trim();
    value = prettyText(value);
    timerTyping = setTimeout(async () => {
      const data = await getList(value);
      setList(data);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setSelectedOptions([...selectedProducts]);
    return () => setSelectedOptions([]);
  }, [selectedProducts, active]);

  useEffect(() => {
    const getListByQueries = async () => {
      setLoading(true);
      const data = await getList(inputValue || "");
      setList(data);
      setLoading(false);
    };

    getListByQueries();
    return () => {
      setOptions([]);
      setSelectedOptions([]);
      setHasNextPage(true);
      setSkip(0);
    };
  }, []);

  useEffect(() => {
    const listProducts = list?.map((product) => ({
      ...product,
      isSelected: Boolean(selectedOptions.find((val) => val.id === product.id)),
    }));
    // Avoid duplicate product on modal when making call many APIs return the same product
    const _options =
      skip === 0 ? listProducts : unionProducts(options, listProducts);
    setOptions(_options);

    clearTimeout(timerTyping);
  }, [list, selectedOptions]);

  return (
    <Modal
      title={"Select product"}
      open={active}
      onClose={handleClose}
      primaryAction={{
        content: "Add product",
        onAction: handleAddProducts,
        disabled: selectedOptions && selectedOptions.length <= 0,
      }}
      onScrolledToBottom={loadMore}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <div className="IronPop-SelectProductModal">
        <Modal.Section>
          <div style={{ minHeight: 510 }}>
            <div
              className="search-product"
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
            >
              <TextField
                onChange={onTextChange}
                value={inputValue}
                prefix={<Icon source={SearchIcon} color="base" />}
                placeholder={"Enter product"}
              />
            </div>

            <ListOptions
              loading={loading}
              options={options}
              onSelect={onSelect}
              q={inputValue}
            />
            {loading && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        </Modal.Section>
      </div>
    </Modal>
  );
};

export default SelectProductModal;
