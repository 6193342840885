import { request } from "./base-api";

const shopApi = {
  install: (queries) => {
    return request("admin-api.shop.install", { queries });
  },
  shopInfo: (queries) => {
    return request("admin-api.shop.shop-info", { queries });
  },
  puTrigger: (params, body) => {
    return request("admin-api.shop.trigger-pu", { params, body });
  },
};

export default shopApi;
