import { request } from "./base-api";

const settingsApi = {
  get: (shopuuid) => {
    return request("admin-api.settings.get-settings", { params: { shopuuid } });
  },
  update: (shopuuid, settings) => {
    return request("admin-api.settings.update-settings", {
      params: { shopuuid },
      body: { settings },
    });
  },
};

export default settingsApi;
