import React from "react";
import OptionItem from "./option-item";

// DONE
const ListOptions = (props) => {
  const { q, options, onSelect, isFull = false, loading } = props;

  // Render rows for data table
  const buildRows = () => {
    let rows = options.map((item, index) => {
      return (
        <OptionItem
          item={item}
          index={index}
          key={index}
          onSelect={onSelect}
          isFull={isFull}
        />
      );
    });
    return rows;
  };
  // Render productRankings on table
  const rowsListOptions = buildRows();
  if (!loading && options && options.length <= 0) {
    return (
      <div style={{ marginTop: 12 }}>
        {q && q !== "" ? (
          <p>
            No results found for <b>{`“${q}”`}</b>
          </p>
        ) : (
          <p>No results found</p>
        )}
      </div>
    );
  }

  return (
    <div className="product-list">
      <ul className="list-available">{rowsListOptions}</ul>
    </div>
  );
};

export default ListOptions;
