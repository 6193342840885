import { BlockStack, Box, Button, Card, Text } from "@shopify/polaris";
import { TextField } from "../../common/components/form";
import OrderCountPreview from "../../common/components/previews/order-count";
import "./styles.css";

const OrderCountContent = ({ values, resetDefault, control }) => {
  const { content, settings, design } = values;

  return (
    <Card>
      <div className="settings content-settings">
        <BlockStack gap={600}>
          <Text as="h2" variant="headingLg" fontWeight="regular">
            Pop content
          </Text>
          <BlockStack gap={600}>
            <BlockStack gap="200">
              <TextField
                name="content.firstLine"
                control={control}
                placeholder="{{orders}}"
                label={
                  <Text as="h2" variant="headingSm">
                    1st line
                  </Text>
                }
              />
              <TextField
                name="content.secondLine"
                control={control}
                placeholder="in last {{time}}"
                label={
                  <Text as="h2" variant="headingSm">
                    2nd line
                  </Text>
                }
              />
            </BlockStack>
            <BlockStack gap={200}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  padding: "8px",
                }}
              >
                <BlockStack gap="100">
                  <div>
                    <span style={{ color: "#6B6B6B", fontWeight: "bold" }}>
                      Variables:
                    </span>
                    <span
                      style={{
                        wordBreak: "break-word",
                        color: "#9B6936",
                      }}
                    >{` {{orders}}, {{time}}`}</span>
                  </div>
                </BlockStack>
              </div>
              <Text>
                <span style={{ fontStyle: "italic" }}>
                  Only support 2-line content.
                </span>
              </Text>
              <Text>
                <span style={{ fontStyle: "italic" }}>
                  {`{{orders}} is getting real data from your Shopify
                            Orders.`}
                </span>
              </Text>
            </BlockStack>
            <Box>
              <Button variant="plain" onClick={resetDefault}>
                Reset to default
              </Button>
            </Box>
          </BlockStack>
        </BlockStack>

        <BlockStack gap={600}>
          <Text as="h2" variant="headingLg" fontWeight="regular">
            Preview
          </Text>
          <Box padding="300">
            <OrderCountPreview
              settings={settings}
              content={content}
              ui={design}
            />
          </Box>
        </BlockStack>
      </div>
    </Card>
  );
};

export default OrderCountContent;
