// Wait ms time
export const waitFor = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

export const setObject = (obj, paths = [], updateProps) => {
  const inputObj = obj === null ? {} : { ...obj };

  if (paths.length === 0) {
    return { ...inputObj, ...updateProps };
  }

  if (paths.length === 1) {
    const key = paths[0];
    return { ...inputObj, [key]: { ...inputObj[key], ...updateProps } };
  }

  const [key, ...rest] = paths;
  const currentNode = inputObj[key];

  const childNode = setObject(currentNode, rest, updateProps);

  return { ...inputObj, [key]: childNode };
};

export const omit = (obj, keys) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key))
  );
};

/**
 * create an array of values from the first array that are not included in the other provided arrays
 * @param {*} array
 * @param  {...any} others
 * @returns
 */
export const difference = (array, ...others) => {
  const otherValues = [].concat(...others); // Flatten the other arrays into a single array
  return array.filter((value) => !otherValues.includes(value));
};

export const unionBy = (arrays, iteratee) => {
  const result = [];
  const seen = new Set();

  for (const array of arrays) {
    for (const item of array) {
      const key =
        typeof iteratee === "function" ? iteratee(item) : item[iteratee];
      if (!seen.has(key)) {
        seen.add(key);
        result.push(item);
      }
    }
  }

  return result;
};

export const prettyText = (text) => {
  return text ? text.trim().replace(/<[^>]+>/g, "") : "";
};
