import { BlockStack, Box, Card, Text } from "@shopify/polaris";
import OrderCountPreview from "../../common/components/previews/order-count";
import { Checkbox, Select, TextField } from "../../common/components/form";

const OrderCountSettings = ({ values, control }) => {
  const { settings, content, design } = values;
  const formatOptions = [
    { label: "Days", value: "day" },
    { label: "Hours", value: "hour" },
  ];

  return (
    <Card>
      <div className="settings properties">
        <BlockStack gap={600}>
          <Text as="h2" variant="headingLg" fontWeight="regular">
            Settings
          </Text>
          <BlockStack gap={500}>
            <Text variant="headingMd">
              <span>Data</span>
            </Text>
            <Box padding="300">
              <BlockStack gap="500">
                <TextField
                  name="settings.lookback"
                  control={control}
                  label={<Text as="h2">Lookback time</Text>}
                  type="integer"
                  suffix="Days"
                  min={1}
                  validate={(value) =>
                    isNaN(value) || value < 1 ? "Positive number only" : ""
                  }
                />
                <BlockStack gap="50">
                  <Checkbox
                    name="settings.hideSmallOrders"
                    control={control}
                    label={
                      <Text as="h2">Hide if the order number is less than</Text>
                    }
                  />
                  <TextField
                    name="settings.smallOrders"
                    control={control}
                    disabled={!settings.hideSmallOrders}
                    type="integer"
                    suffix="Orders"
                    min={1}
                    validate={(value) =>
                      isNaN(value) || value < 1 ? "Positive number only" : ""
                    }
                  />
                </BlockStack>
              </BlockStack>
            </Box>
            <Text variant="headingMd">
              <span>Display options</span>
            </Text>
            <Box padding="300">
              <BlockStack gap="500">
                <Checkbox
                  name="settings.showOnMobile"
                  control={control}
                  label={<Text as="h2">Show on mobile</Text>}
                />
                <Select
                  name="settings.format"
                  control={control}
                  label={<Text as="h2">Format</Text>}
                  options={formatOptions}
                />
              </BlockStack>
            </Box>
          </BlockStack>
        </BlockStack>
        <BlockStack gap={600}>
          <Text as="h2" variant="headingLg" fontWeight="regular">
            Preview
          </Text>
          <Box padding="300">
            <OrderCountPreview
              settings={settings}
              content={content}
              ui={design}
            />
          </Box>
        </BlockStack>
      </div>
    </Card>
  );
};

export default OrderCountSettings;
