import React from "react";
import { BlockStack, Box, Card, Divider, Layout, Text } from "@shopify/polaris";
import "./styles.css";
import {
  LIST_ITEM_DESKTOP_POSITION,
  LIST_ITEM_MOBILE_POSITION,
} from "../../common/constants/settings";
import { MAX_VALUE_NUMBER } from "../../common/constants/common";
import { Checkbox, Select, TextField } from "../../common/components/form";
import LabelTooltip from "../../common/components/custom/label-tooltip";

const GeneralSettings = ({ control, formValues }) => {
  return (
    <div className="properties">
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap={500}>
              <Text as="h2" variant="headingMd">
                Display options
              </Text>
              <BlockStack gap={100}>
                <BlockStack gap={50} align="center">
                  <div className="row">
                    <BlockStack align="center" gap={200}>
                      <Text truncate fontWeight="regular" variant="headingMd">
                        Desktop position
                      </Text>
                      <Select
                        control={control}
                        name="desktopPosition"
                        options={LIST_ITEM_DESKTOP_POSITION}
                      />
                    </BlockStack>
                    <BlockStack align="center" gap={200}>
                      <Text fontWeight="regular" variant="headingMd">
                        Mobile position
                      </Text>
                      <Select
                        control={control}
                        name="mobilePosition"
                        options={LIST_ITEM_MOBILE_POSITION}
                      />
                    </BlockStack>
                  </div>
                  <Box id="divider">
                    <Divider borderColor="border" />
                  </Box>
                </BlockStack>
                <BlockStack gap={200}>
                  <div className="row">
                    {/* <BlockStack id="block-start-display-time" gap={200}> */}
                    <BlockStack gap={200}>
                      <LabelTooltip
                        label="Display time"
                        tooltip="The duration that a notification stays on your site"
                      />
                      <TextField
                        control={control}
                        name="displayTime"
                        min={0}
                        max={MAX_VALUE_NUMBER}
                        disabled={formValues.displayRandomOrder}
                        type="number"
                        suffix={<span>seconds</span>}
                      />
                    </BlockStack>
                    <BlockStack gap={200}>
                      <Text fontWeight="regular" variant="headingMd">
                        Maximum per page
                      </Text>
                      <TextField
                        control={control}
                        name="maxPerPage"
                        min={0}
                        max={50}
                        disabled={formValues.displayRandomOrder}
                        suffix={<span>notifications</span>}
                      />
                    </BlockStack>
                  </div>
                  <Box id="divider">
                    <Divider borderColor="border" />
                  </Box>
                </BlockStack>
                <BlockStack gap={200}>
                  <div className="row">
                    <BlockStack gap={200}>
                      <Text fontWeight="regular" variant="headingMd">
                        Delay time between notifications
                      </Text>
                      <TextField
                        control={control}
                        name="delayBetweenNotifications"
                        min={0}
                        max={MAX_VALUE_NUMBER}
                        disabled={formValues.randomizeDelay}
                        type="number"
                        suffix={<span>seconds</span>}
                      />

                      <div style={{ marginTop: 12 }}>
                        <Checkbox
                          control={control}
                          name="randomizeDelay"
                          label="Randomize delay time between notifications"
                        />
                      </div>
                    </BlockStack>
                    <BlockStack gap={200}>
                      <Text fontWeight="regular" variant="headingMd">
                        Delay time to show popup after loading page
                      </Text>

                      <TextField
                        control={control}
                        name="delayToShowPopup"
                        type="number"
                        min={0}
                        max={MAX_VALUE_NUMBER}
                        suffix={<span>seconds</span>}
                      />
                    </BlockStack>
                  </div>
                </BlockStack>
              </BlockStack>
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </div>
  );
};

export default GeneralSettings;
