import { useState } from "react";
import {
  Box,
  Card,
  Text,
  BlockStack,
  InlineStack,
  Badge,
  Button,
  Collapsible,
} from "@shopify/polaris";
import {
  CheckCircleFillIcon,
  DashedCircleIcon,
} from "../../common/components/icons";
import "./styles.css";

const SetupGuide = ({ SetupGuideList }) => {
  const completedSteps = SetupGuideList.filter((step) => step.completed).length;

  const totalSteps = SetupGuideList.length;

  const currentStep = () => {
    let defaultValues = {
      app_embed: false,
      customize_pop: false,
      review_app: false,
    };
    if (!SetupGuideList[0].completed || completedSteps.length === 3) {
      defaultValues = {
        ...defaultValues,
        app_embed: true,
      };
      return defaultValues;
    }
    if (!SetupGuideList[1].completed) {
      defaultValues = {
        ...defaultValues,
        customize_pop: true,
      };
      return defaultValues;
    }
    if (!SetupGuideList[2].completed) {
      defaultValues = {
        ...defaultValues,
        review_app: true,
      };
      return defaultValues;
    }
  };

  const [steps, setSteps] = useState(currentStep());

  const openStep = (index = 0) => {
    if (completedSteps.length === 3) return;
    if (index >= SetupGuideList.length) {
      index = 0;
      if (SetupGuideList[0].completed && !SetupGuideList[1].completed) {
        index = 1;
      }
    }

    const ids = ["app_embed", "customize_pop", "review_app"];
    const current = ids[index > 2 ? 0 : index];

    const newStepsSettings = {
      app_embed: false,
      customize_pop: false,
      review_app: false,
      [current]: !steps[current],
    };
    setSteps(newStepsSettings);
  };

  return (
    <Card>
      <Box background="bg-subdued">
        <BlockStack gap="200">
          <Text variant="headingMd" as="h2">
            Setup guide
          </Text>
          <BlockStack gap="200">
            <Text as="p" color="subdued">
              Follow this tailored guide to set up your sales notifications.
            </Text>
            <Box paddingBlockEnd={200}>
              <Badge>
                {completedSteps} / {totalSteps} completed
              </Badge>
            </Box>
          </BlockStack>
          <BlockStack gap="200">
            <div className="setup-guide-list">
              {SetupGuideList.map((item, itemIndex) => {
                const { id, title, description, completed, actions, src } =
                  item;

                const open = steps ? steps[id] : itemIndex === 0 ? true : false;
                return (
                  <div className="item" key={id}>
                    <div>
                      <div
                        className="heading"
                        onClick={() => openStep(itemIndex)}
                      >
                        <InlineStack gap={200}>
                          {completed ? (
                            <CheckCircleFillIcon />
                          ) : (
                            <DashedCircleIcon />
                          )}
                          <Text
                            variant="bodyMd"
                            fontWeight={completed ? "bold" : "normal"}
                          >
                            {title}
                          </Text>
                        </InlineStack>
                      </div>
                      <Collapsible
                        open={open}
                        id={`${id}-collapse`}
                        transition={{
                          duration: "500ms",
                          timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                      >
                        <div className="content">
                          {description && (
                            <BlockStack gap={400}>
                              <Text as="span" color="subdued">
                                {description}{" "}
                                {itemIndex !== SetupGuideList?.length - 1 && (
                                  <a
                                    id={id}
                                    href="https://ironpop.io/sales-popups.html"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      color: "rgba(0, 91, 211, 1)",
                                    }}
                                  >
                                    Learn more
                                  </a>
                                )}
                              </Text>
                              <Box minHeight="500">
                                {actions?.map((action, index) => (
                                  <Button
                                    url={action.url}
                                    key={index}
                                    disabled={action?.disabled}
                                    onClick={() => {
                                      action.onClick();
                                      openStep(itemIndex + 1);
                                    }}
                                    size="slim"
                                  >
                                    {action.content}
                                  </Button>
                                ))}
                              </Box>
                            </BlockStack>
                          )}
                        </div>
                      </Collapsible>
                    </div>
                    {src && (
                      <Collapsible
                        open={open}
                        id={`${id}-collapse-image`}
                        transition={{
                          duration: "500ms",
                          timingFunction: "ease-in-out",
                        }}
                        expandOnPrint
                      >
                        <Box>
                          <img
                            src={src}
                            alt=""
                            style={{
                              maxWidth: "120px",
                            }}
                          />
                        </Box>
                      </Collapsible>
                    )}
                  </div>
                );
              })}
            </div>
          </BlockStack>
        </BlockStack>
      </Box>
    </Card>
  );
};

export default SetupGuide;
