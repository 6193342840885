/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BlockStack, Card, Text } from "@shopify/polaris";

import { Checkbox, Select, TextField } from "../../../common/components/form";
import { NOTIFICATION_ORDER_OPTIONS } from "../../../common/constants/sale-noti";

const DisplayOptions = ({ control }) => {
  return (
    <Card>
      <div className="properties">
        <BlockStack gap={300}>
          <Text variant="headingLg" fontWeight="regular">
            Display options
          </Text>
          <BlockStack gap="300">
            <Checkbox
              name="settings.showOnMobile"
              control={control}
              label="Show on Mobile"
            />
            <Checkbox
              name="settings.repeatSalesNotifications"
              control={control}
              label="Repeat sales notifications when there are no new orders synced"
            />
            <TextField
              label="Only display synced orders that were created within the last:"
              name="settings.onlyDisplaySyncedOrders"
              control={control}
              type="integer"
              suffix="Days"
              min={1}
              max={9999}
              validate={(value) =>
                isNaN(value) || value < 0 || value > 9999
                  ? "Positive number only"
                  : ""
              }
            />
            <TextField
              label="Hide “time ago” after:"
              name="settings.hideTimeAgoAfter"
              control={control}
              type="integer"
              suffix="Days"
              min={1}
              max={9999}
              validate={(value) =>
                isNaN(value) || value < 0 || value > 9999
                  ? "Positive number only"
                  : ""
              }
            />
            <Select
              control={control}
              label="Notification order:"
              name="settings.notificationOrder"
              options={NOTIFICATION_ORDER_OPTIONS}
            />
          </BlockStack>
        </BlockStack>
      </div>
    </Card>
  );
};

export default DisplayOptions;
