import React from "react";
import { BlockStack, Box, Button, Card, Text } from "@shopify/polaris";
import { Checkbox, TextField } from "../../common/components/form";
import SalesNotiPreview from "../../common/components/previews/sale-noti";
import "./styles.css";

const SalesNotiContent = ({ values, resetDefault, control }) => {
  const { content, design, showBrand } = values;

  return (
    <Card>
      <div className="content content-settings">
        <BlockStack gap={600}>
          <Text as="h2" variant="headingLg" fontWeight="regular">
            Pop content
          </Text>
          <BlockStack gap={600}>
            <BlockStack gap="500">
              <BlockStack gap={300}>
                <TextField
                  name="content.titleText"
                  control={control}
                  label={
                    <Text as="h2" variant="headingSm">
                      Title
                    </Text>
                  }
                  placeholder="{{first_name}} in {{country}} purchased"
                  maxLength={100}
                />
                <div style={{ backgroundColor: "#f2f2f2", padding: "8px" }}>
                  <span style={{ color: "#6B6B6B", fontWeight: "bold" }}>
                    Variables:
                  </span>
                  <span
                    style={{ wordBreak: "break-word", color: "#9B6936" }}
                  >{` {{city}}, {{country}}, {{full_name}}, {{first_name}}, {{last_name}}`}</span>
                </div>
              </BlockStack>
              <div style={{ fontStyle: "italic" }}>
                * For privacy reasons, the buyer's name will be displayed in a
                masked format, for example, J***h.
              </div>
              <BlockStack gap={300}>
                <TextField
                  name="content.productNameText"
                  control={control}
                  placeholder="{{product_link}}, {{product_title}}"
                  label={
                    <Text as="h2" variant="headingSm">
                      Product name
                    </Text>
                  }
                />
                <div style={{ backgroundColor: "#f2f2f2", padding: "8px" }}>
                  <span style={{ color: "#6B6B6B", fontWeight: "bold" }}>
                    Variables:
                  </span>
                  <span
                    style={{ wordBreak: "break-word", color: "#9B6936" }}
                  >{` {{product_title}}, {{product_link}}`}</span>
                </div>
              </BlockStack>
              <BlockStack gap={200}>
                <Text as="h2" variant="headingSm">
                  Time
                </Text>
                <Checkbox
                  name="content.enableTimeAgo"
                  control={control}
                  label="Enable “time ago“"
                />
              </BlockStack>
            </BlockStack>

            <Box>
              <Button variant="plain" onClick={resetDefault}>
                Reset to default
              </Button>
            </Box>
          </BlockStack>
        </BlockStack>

        <BlockStack gap={600}>
          <Text variant="headingLg" fontWeight="regular">
            Preview
          </Text>
          <SalesNotiPreview
            content={{ ...content }}
            settings={design}
            showBrand={showBrand}
          />
        </BlockStack>
      </div>
    </Card>
  );
};

export default SalesNotiContent;
