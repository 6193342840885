import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";

const Toggle = ({
  checked,
  defaultChecked,
  disabled,
  checkedLabel,
  uncheckedLabel,
  labelSuffix,
  onChange,
}) => {
  const [status, setStatus] = useState(checked || defaultChecked);
  const [label, setLabel] = useState(
    checked || defaultChecked
      ? checkedLabel || uncheckedLabel
      : uncheckedLabel || checkedLabel
  );

  useEffect(() => {
    setStatus(checked || defaultChecked);
    setLabel(
      checked || defaultChecked
        ? checkedLabel || uncheckedLabel
        : uncheckedLabel || checkedLabel
    );
  }, [checked, checkedLabel, defaultChecked, uncheckedLabel]);

  const handleOnClick = useCallback(() => {
    if (checked !== undefined || disabled) return;

    setLabel(
      !status ? checkedLabel || uncheckedLabel : uncheckedLabel || checkedLabel
    );
    setStatus(!status);
    if (onChange) onChange(status);
  }, [checked, checkedLabel, disabled, onChange, status, uncheckedLabel]);

  return (
    <div className="ip-toggle">
      {label !== "" && !labelSuffix && (
        <span
          className={`ip-toggle-label--prefix ${
            disabled ? "ip-toggle-label--disabled" : ""
          }`}
        >
          {label}
        </span>
      )}
      <button
        className={`ip-toggle-bg ${
          status
            ? disabled
              ? "ip-toggle-bg--checked ip-toggle-bg--checked-disabled"
              : "ip-toggle-bg--checked"
            : disabled
            ? "ip-toggle-bg--disabled"
            : ""
        }`}
        type="button"
        onClick={handleOnClick}
      >
        <span
          className={`ip-toggle-point ${
            status ? "ip-toggle-point--checked" : ""
          }`}
        ></span>
      </button>
      {label !== "" && labelSuffix && (
        <span
          className={`ip-toggle-label--suffix ${
            disabled ? "ip-toggle-label--disabled" : ""
          }`}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default Toggle;
