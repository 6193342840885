import React from "react";
import { BlockStack, Card, InlineStack, Spinner, Text } from "@shopify/polaris";
import "./styles.css";

const PageLoading = () => {
  return (
    <div className="IronPop-pageLoading">
      <Card roundedAbove="sm">
        <BlockStack gap="200">
          <Text variant="headingLg">Iron Pop</Text>
          <InlineStack gap={100}>
            <Spinner size="small" />
            <Text>Page loading...</Text>
          </InlineStack>
        </BlockStack>
      </Card>
    </div>
  );
};

export default PageLoading;
