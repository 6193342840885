/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBar } from '@shopify/app-bridge-react';
import { useForm } from 'react-hook-form';
import { Button, Page } from '@shopify/polaris';

import './styles.css';
import PowerUserSettings from '../../features/settings/power-user-settings';
import GeneralSettings from '../../features/settings/general-settings';
import { getSettings, updateSettings } from '../../stores/slices/settingsSlice';
import { triggerPU } from '../../stores/slices/shopSlice';

import { buildSettingsPayload } from '../../common/helper';
import { setObject } from '../../common/utils';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const shop = useSelector((state) => state.shop);

  const { data: settingsData = {}, loading } = settings;
  const { settingPopups = {} } = settingsData;
  const defaultWatermark = settingPopups.brandingSettings?.watermark ?? true;

  const [watermark, setWatermark] = useState(true);

  useEffect(() => {
    if (!settings.loaded) {
      dispatch(getSettings(shop.shop_uuid));
    }
  }, []);

  const setFormValues = (settings = {}) => {
    return {
      desktopPosition: settings?.desktopPosition || 'Bottom Left',
      mobilePosition: settings?.mobilePosition || 'Top',
      displayTime: settings?.displayTime || 10,
      displayRandomOrder: settings?.displayRandomOrder || false,
      maxPerPage: settings?.maxPerPage || 10,
      delayBetweenNotifications: settings?.delayBetweenNotifications || 10,
      randomizeDelay: settings?.randomizeDelay || false,
      delayToShowPopup: settings?.delayToShowPopup || 3,
    };
  };

  const isFormDirty = () => {
    if (!settingPopups.displayOptionsSettings) return false;

    if (watermark !== defaultWatermark) {
      return true;
    }

    const keys = Object.keys(formValues);
    for (const key of keys) {
      if (String(formValues[key]) !== String(settingPopups.displayOptionsSettings[key])) {
        return true;
      }
    }

    return false;
  };

  const { control, reset, watch, handleSubmit } = useForm({
    defaultValues: setFormValues({}),
  });
  const formValues = watch();

  useEffect(() => {
    if (!_.isEmpty(settingPopups)) {
      setWatermark(defaultWatermark);
      reset(
        setFormValues(
          _.pick(settingPopups.displayOptionsSettings, [
            'desktopPosition',
            'mobilePosition',
            'displayTime',
            'displayRandomOrder',
            'maxPerPage',
            'delayBetweenNotifications',
            'randomizeDelay',
            'delayToShowPopup',
          ])
        )
      );
    }
  }, [settingPopups]);

  const triggerPowerUser = async () => {
    dispatch(
      triggerPU({
        params: { shopuuid: shop.shop_uuid },
        body: { shop: shop.id },
      })
    );
  };

  const updateWatermark = (checked) => {
    setWatermark(!checked);
  };

  const onSubmit = () => {
    const payload = buildSettingsPayload(
      shop.shop_uuid,
      settingsData,
      'settingPopups.displayOptionsSettings',
      formValues
    );

    payload.settings.settingPopups = setObject(payload.settings.settingPopups, ['brandingSettings'], { watermark });

    dispatch(updateSettings(payload));
    reset(formValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        title="Settings"
        primaryAction={
          <Button id="btn-save" loading={loading} disabled={!isFormDirty()} submit variant="primary">
            Save
          </Button>
        }
      >
        <div className="IronPop-settings">
          <SaveBar open={isFormDirty()} id="settings-save-bar">
            <button type="submit" variant="primary" disabled={loading}></button>
            <button
              disabled={loading}
              type="button"
              onClick={() => {
                setWatermark(defaultWatermark);
                reset();
              }}
            ></button>
          </SaveBar>
          <PowerUserSettings
            shop={shop}
            watermark={watermark}
            triggerPowerUser={triggerPowerUser}
            updateWatermark={updateWatermark}
          />
          <GeneralSettings control={control} formValues={formValues} />
        </div>
      </Page>
    </form>
  );
};

export default SettingsPage;
