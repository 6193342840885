/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BlockStack,
  Button,
  InlineGrid,
  InlineStack,
  Page,
  Text,
} from "@shopify/polaris";
import Toggle from "../../common/components/custom/toggle";
import { POP_TYPES_PROPERTIES } from "../../common/constants/pop-types";
import "./styles.css";
import { getSettings, updateSettings } from "../../stores/slices/settingsSlice";
import { useNavigate } from "react-router-dom";
import { buildSettingsPayload } from "../../common/helper";

const PopTypesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector((state) => state.settings);
  const shop = useSelector((state) => state.shop);

  const { data: settingsData = {} } = settings;
  const { status: settingsStatus = {} } = settingsData;

  useEffect(() => {
    if (!settings.loaded) {
      dispatch(getSettings(shop.shop_uuid));
    }
  }, []);

  const onChangePopTypeStatus = (type, value) => {
    dispatch(
      updateSettings(
        buildSettingsPayload(shop.shop_uuid, settingsData, "status", {
          [type]: value,
        })
      )
    );
  };

  return (
    <Page title="Pop types">
      <div className="IronPop-popTypes">
        <BlockStack gap={400}>
          {POP_TYPES_PROPERTIES.map((pt) => (
            <div key={pt.key} className="Polaris-LegacyCard">
              <div className="Polaris-MediaCard">
                <div
                  className="Polaris-MediaCard__MediaContainer"
                  style={{
                    backgroundColor: `${pt.image ? "#fffff" : "#fee0bd"}`,
                  }}
                >
                  <img
                    alt=""
                    width="100%"
                    height="100%"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    src={
                      pt.image
                        ? pt.image
                        : "https://cdn.iron-pop.com/pop-types/coming-soon-img.png"
                    }
                  />
                </div>
                <div className="Polaris-MediaCard__InfoContainer info-column">
                  <BlockStack gap="500">
                    <InlineStack gap="300">
                      <BlockStack gap="300">
                        <InlineGrid columns="1fr auto">
                          <Text variant="headingMd" as="h2">
                            {pt.title}
                          </Text>
                          {pt.toggle && (
                            <Toggle
                              defaultChecked={
                                settingsStatus[pt.key] ? true : false
                              }
                              checkedLabel="On"
                              uncheckedLabel="Off"
                              onChange={(checked) => {
                                onChangePopTypeStatus(pt.key, !checked);
                              }}
                            />
                          )}
                        </InlineGrid>
                        <Text>{pt.content}</Text>
                      </BlockStack>
                    </InlineStack>
                    <InlineStack gap="300">
                      <Button
                        variant="primary"
                        disabled={pt.primaryActionStatus === "disable"}
                        onClick={() => {
                          navigate(pt.primaryActionUrl);
                        }}
                      >
                        {pt.primaryAction}
                      </Button>
                      {pt.secondaryAction !== "" && (
                        <Button variant="plain">{pt.secondaryAction}</Button>
                      )}
                    </InlineStack>
                  </BlockStack>
                </div>
              </div>
            </div>
          ))}
        </BlockStack>
      </div>
    </Page>
  );
};

export default PopTypesPage;
