/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import Papa from "papaparse";
import {
  BlockStack,
  Box,
  Button,
  Modal,
  Card,
  DropZone,
  Icon,
  InlineStack,
  Text,
  Spinner,
} from "@shopify/polaris";
import { DeleteIcon, PageDownIcon, FileIcon } from "@shopify/polaris-icons";

import { Checkbox } from "../../../common/components/form";
import salesNotiApi from "../../../apis/sales-noti-api";

import { LIST_ITEM_IMPORT_SOURCE } from "../../../common/constants/sale-noti";
import {
  DataSourceValidationSchema,
  parseExcelFile,
} from "../../../common/helper/sale-noti";
import { useAppBridge } from "@shopify/app-bridge-react";

const DataSource = ({ values, originSettings, control, setValue }) => {
  const { settings, shopuuid } = values;
  const [active, setActive] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorImport, setErrorImport] = useState(null);

  const shopify = useAppBridge();

  let importData = [];
  const importedFile =
    settings?.importedFiles && settings?.importedFiles.length > 0
      ? settings?.importedFiles[0]
      : "";
  const originImportedFiles =
    settings?.originImportedFiles && settings?.originImportedFiles.length > 0
      ? settings?.originImportedFiles[0]
      : "";

  const handleMutateImportToSystem = async () => {
    setLoading(true);
    await salesNotiApi.importOrders(shopuuid, {
      import_data: data,
      file_name: file?.name,
    });
    setValue("settings.originImportedFiles", [file?.name]);
    setValue("settings.importedFiles", [file?.name]);
    setLoading(false);
    shopify.toast.show("Data imported successfully!");
    setFile(null);
    setActive(false);
  };

  const handleExportOrders = async () => {
    await salesNotiApi.exportOrdersToCSV(shopuuid, {
      file_name: importedFile,
    });
  };

  const downloadFile = (url = null) => {
    window.open(
      url ? url : "https://cdn.iron-pop.com/files/ironpop-orders-import.csv",
      "_blank"
    );
  };

  const removeImportFile = async () => {
    setLoading(true);
    await salesNotiApi.updateSalesNoti(shopuuid, {
      settings: { ...originSettings, importedFiles: [] },
    });
    setLoading(false);
    shopify.toast.show("Data removed successfully!");
  };

  const handleClickRemoveFile = async () => {
    await removeImportFile();
    setValue("settings.importedFiles", [], {
      shouldDirty: false,
    });
    setValue("settings.originImportedFiles", [], {
      shouldDirty: false,
    });
    setFile(null);
    setData([]);
    setErrorImport(null);
    setModalDelete(false);
  };

  const handleDropZoneDrop = useCallback(
    async (_dropFiles, acceptedFiles, _rejectedFiles) => {
      const validTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];
      const maxSize = 2 * 1024 * 1024;
      const uploadedFile = acceptedFiles[0];
      if (_rejectedFiles?.length > 0) {
        return;
      }

      // validate files
      const validFiles = acceptedFiles.filter((file) => {
        if (!validTypes.includes(file.type)) {
          setErrorImport(
            `File ${file?.name} is not a valid type. Only CSV and XLSX files are allowed.`
          );
          return false;
        }
        if (file.size > maxSize) {
          setErrorImport(`File ${file?.name} exceeds the size limit of 25MB.`);
          return false;
        }
        return true;
      });

      if (validFiles) {
        setFile(uploadedFile);
        setErrorImport(null);
        setData(null);
        const fileType = uploadedFile?.name?.split(".").pop().toLowerCase();
        if (fileType === "csv") {
          importData = await new Promise((resolve, reject) => {
            let headers = [
              "customer_name",
              "location",
              "create_at",
              "product_name",
              "product_url",
              "product_image",
            ];
            let readData = [];
            let counting = 0;
            Papa.parse(uploadedFile, {
              skipEmptyLines: true,
              step: (_row, parser) => {
                // read data from 3rd line
                if (counting >= 2) {
                  let data = {};
                  headers.forEach((header, index) => {
                    data[header] = _row.data[index];
                  });
                  readData.push(data);
                  counting++;
                  // check if exceed 102
                  if (counting > 102) {
                    // 102 bao gồm header và dòng trước dòng thứ 3
                    setErrorImport("The number of items exceeds 100.");
                    parser.abort();
                  }
                } else {
                  counting++;
                }
              },
              complete: () => {
                resolve(readData);
              },
              error: (error) => {
                console.error("Error reading CSV file:", error);
                reject(error);
              },
            });
          });
        } else if (["xls", "xlsx"].includes(fileType)) {
          importData = await parseExcelFile(uploadedFile);
        }
        try {
          const validData = await DataSourceValidationSchema.validate(
            importData,
            { abortEarly: false }
          );
          setData(validData);
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            // if validate error: Yup.ValidationError
            setErrorImport(
              "Please ensure the values in this file are in the correct format before importing."
            );
          } else {
            setErrorImport(`Unexpected error: ${error?.stack}`);
          }
        }
      }
    },
    []
  );

  useEffect(() => {
    if (!!file && data?.length > 0) {
      setActive(true);
    }
  }, [file, data]);

  const fileUpload = !importedFile && !file && (
    <DropZone.FileUpload
      actionTitle="Add file"
      actionHint="Accept CSV files no larger than 2MB and containing no more than 100 items."
    />
  );

  const existedFiles = (importedFile || file) && (
    <DropZone.FileUpload
      actionTitle="Replace file"
      actionHint="Accept CSV files no larger than 2MB and containing no more than 100 items."
    />
  );

  return (
    <Card>
      <Modal
        open={active}
        title="Import orders by CSV"
        onClose={() => {
          setActive(false);
          setFile(null);
        }}
        primaryAction={{
          content: "Continue to import",
          loading,
          onAction: () => handleMutateImportToSystem(),
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setActive(false);
              setFile(null);
            },
          },
        ]}
      >
        <Box padding={400}>
          <div>Are you sure you want to import this file into IronPop?</div>
          {originImportedFiles && (
            <p>
              Importing will overwrite the file <b>{originImportedFiles}</b>{" "}
              currently in the database. This action cannot be undone.
            </p>
          )}
        </Box>
      </Modal>
      <Modal
        open={modalDelete}
        title="Confirm delete file"
        onClose={() => {
          setModalDelete(false);
        }}
        primaryAction={{
          content: "Delete",
          loading: loading,
          destructive: true,
          onAction: () => handleClickRemoveFile(),
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => {
              setModalDelete(false);
            },
          },
        ]}
      >
        <Box padding={400}>
          <div>
            Are you sure you want to delete <b>{originImportedFiles}</b> file ?
          </div>
        </Box>
      </Modal>
      <BlockStack gap={600}>
        <Text variant="headingLg" fontWeight="regular">
          Data source
        </Text>
        <div style={{ width: "50%" }}>
          <BlockStack gap={200}>
            {LIST_ITEM_IMPORT_SOURCE?.map((checkBoxItem) => (
              <Checkbox
                name={`settings.${checkBoxItem?.name}`}
                control={control}
                key={checkBoxItem.id}
                label={checkBoxItem.checkBoxLabel}
              />
            ))}
            <BlockStack gap={300}>
              <BlockStack gap={200}>
                <Text>Import Data</Text>
                {/* {isPendingImport && (
              <Spinner
                accessibilityLabel="Small spinner example"
                size="small"
              />
            )} */}
                <InlineStack align="space-between">
                  <p
                    style={{
                      cursor: "pointer",
                      color: "var(--p-color-text-link)",
                    }}
                    onClick={downloadFile}
                  >
                    Download Sample CSV
                  </p>
                </InlineStack>
              </BlockStack>
              {(importedFile || file) && (
                <InlineStack align="space-between">
                  <InlineStack gap={50} blockAlign="center">
                    <Icon source={FileIcon} />
                    <div>{importedFile ? importedFile : file?.name}</div>
                  </InlineStack>
                  <InlineStack gap={100} blockAlign="center">
                    {loading && (
                      <div style={{ width: 12, height: 12 }}>
                        <Spinner />
                      </div>
                    )}
                    {importedFile && !file && (
                      <Button
                        onClick={handleExportOrders}
                        icon={PageDownIcon}
                        variant="plain"
                      />
                    )}
                    <Button
                      onClick={() => setModalDelete(true)}
                      icon={DeleteIcon}
                      variant="plain"
                    />
                  </InlineStack>
                </InlineStack>
              )}
              <DropZone
                disabled={!settings?.importOrders}
                onDrop={handleDropZoneDrop}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                variableHeight
                allowMultiple={false}
              >
                {fileUpload}
                {existedFiles}
              </DropZone>

              {errorImport && <Text tone="critical"> {errorImport} </Text>}
              <Text breakWord>
                <a
                  href="mailto:support@ironpop.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact us
                </a>{" "}
                if you need more help!
              </Text>
            </BlockStack>
          </BlockStack>
        </div>
      </BlockStack>
    </Card>
  );
};

export default DataSource;
