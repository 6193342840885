import { Controller } from "react-hook-form";
import { TextField as PolarisTextField } from "@shopify/polaris";

const TextField = ({
  control,
  label,
  name,
  type = "text",
  min = 0,
  max = 100,
  multiline,
  suffix,
  disabled,
  validate,
  placeholder,
  helpText,
  rules,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <PolarisTextField
            label={label}
            type={type}
            min={min}
            max={max}
            helpText={helpText}
            disabled={disabled}
            suffix={suffix}
            multiline={multiline}
            name={field.name}
            value={field.value}
            onChange={(value) => field.onChange(value)}
            onBlur={field.onBlur}
            error={
              validate && fieldState?.isTouched
                ? validate(field.value)
                : fieldState?.error
                ? fieldState?.error?.message
                : ""
            }
            autoComplete="off"
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

export default TextField;
