import React from "react";
import { Controller } from "react-hook-form";
import { Select as PolarisSelect } from "@shopify/polaris";

const Select = ({ control, name, label, options }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...data }, fieldState: { error } }) => {
        return (
          <PolarisSelect
            {...data}
            label={label}
            options={options}
            error={error && error.message}
          />
        )
      }}
    />
  );
};

export default Select;
