import { Modal } from "@shopify/polaris";

const NotifyModal = ({ email, active, onClose }) => {
  return (
    <Modal
      open={active}
      onClose={onClose}
      title="Your request has been sent!"
      primaryAction={{
        content: "Close",
        onAction: onClose,
      }}
    >
      <Modal.Section>
        <p>
          We are reviewing your request and will contact you shortly! This
          process usually takes less than 30 minutes to complete.
        </p>
        <p style={{ marginTop: "20px" }}>
          You can close this popup, and we will reach out via email at{" "}
          <b>{email}</b> once it's done!
        </p>
      </Modal.Section>
    </Modal>
  );
};

export default NotifyModal;
