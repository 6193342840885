import { Controller } from "react-hook-form";
import ColorPickerInput from "../custom/color-picker";

const ColorPicker = ({ control, name, defaultValue, setValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, value = defaultValue } }) => (
        <ColorPickerInput
          onColorChange={(color) =>
            setValue(name, color, { shouldDirty: true })
          }
          hexColor={value}
        />
      )}
    />
  );
};

export default ColorPicker;
