import * as Yup from "yup";
import ExcelJS from "exceljs";
// validate

export const DataSourceValidationSchema = Yup.array().of(
  Yup.object().shape({
    customer_name: Yup.string()
      .required("Customer name is required")
      .max(100, "Customer name cannot exceed 100 characters"),
    location: Yup.string()
      .required("Location is required")
      .max(100, "Location cannot exceed 100 characters"),
    create_at: Yup.string()
      .required("Create at is required")
      .max(100, "Create at cannot exceed 100 characters"),
    product_name: Yup.string()
      .required("Product name is required")
      .max(100, "Product name cannot exceed 100 characters"),
    product_url: Yup.string()
      .required("Product url is required")
      .max(200, "Product url cannot exceed 200 characters"),
    product_image: Yup.string().max(
      200,
      "Product image url cannot exceed 200 characters"
    ),
  })
);

export const parseExcelFile = async (file, setErrorImport) => {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);
  const worksheet = workbook.getWorksheet(1); // Get the first sheet
  const rows = [];
  const maxItems = 100;
  let itemCount = 0;
  const headers = [
    "customer_name",
    "location",
    "create_at",
    "product_name",
    "product_url",
    "product_image",
  ];

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber === 2) {
      // Second row as header
      // const header = row.values.slice(1);
      worksheet.eachRow((rowData, rowIndex) => {
        if (rowIndex > 2) {
          const obj = {};
          headers.forEach((key, index) => {
            let cellValue = rowData.values[index + 1];
            obj[key] = cellValue;
          });
          rows.push(obj);

          // Increment item count
          itemCount++;

          // Check if item count exceeds max items
          if (itemCount > maxItems) {
            setErrorImport("The number of items exceeds 100.");
            return rows; // Return the rows parsed so far
          }
        }
      });
    }
  });

  return rows;
};
