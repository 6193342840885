import { request } from "./base-api";

const orderCountApi = {
  getOrderCount: (shopuuid) => {
    return request("admin-api.order-count.get-order-count", {
      params: { shopuuid },
    });
  },
  updateOrderCount: (shopuuid, payload) => {
    return request("admin-api.order-count.update-order-count", {
      params: { shopuuid },
      body: payload,
    });
  },
};

export default orderCountApi;
