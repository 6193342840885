/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Page } from '@shopify/polaris';
import { SaveBar } from '@shopify/app-bridge-react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import './styles.css';
import FestiveThemes from '../../features/design/festive-themes';
import ThemeSettings from '../../features/design/theme-settings';
import { triggerPU } from '../../stores/slices/shopSlice';
import { getSettings, updateSettings } from '../../stores/slices/settingsSlice';

import commonsApi from '../../apis/common-api';

import { DEFAULT_THEMES_SETTINGS, THEME_BASIC } from '../../common/constants/design';
import { buildSettingsPayload } from '../../common/helper';

const DesignPage = () => {
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const settings = useSelector((state) => state.settings);

  const setFormValues = (settings, themeCode = THEME_BASIC) => {
    return {
      backgroundColor: settings.backgroundColor,
      timeColor: settings.timeColor,
      textColor: settings.textColor,
      highlightColor: settings.highlightColor,
      borderRadius: settings.borderRadius,
      background: settings.background,
      decorator: settings.decorator,
      themeCode: settings.themeCode || themeCode,
    };
  };

  const { data: settingsData = {}, loading } = settings;
  const { settingPopups = {} } = settingsData;
  const watermark = settingPopups.brandingSettings?.watermark || true;
  const defaultThemeSettings = setFormValues(
    _.pick(settingPopups.uiThemeSettings || {}, [
      'backgroundColor',
      'timeColor',
      'textColor',
      'highlightColor',
      'borderRadius',
      'background',
      'decorator',
      'themeCode',
    ])
  );

  const [festThemes, setFestThemes] = useState(DEFAULT_THEMES_SETTINGS);
  const [selectedTheme, setSelectedTheme] = useState(THEME_BASIC);

  useEffect(() => {
    const fetchFestThemes = async () => {
      const response = await commonsApi.getFestThemes();
      setFestThemes(response.data);
    };

    fetchFestThemes();
  }, []);

  useEffect(() => {
    if (!settings.loaded) {
      dispatch(getSettings(shop.shop_uuid));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(settingPopups)) {
      const uiThemeSettings = settingPopups.uiThemeSettings;
      setSelectedTheme(uiThemeSettings.themeCode || THEME_BASIC);
      reset(defaultThemeSettings);
    }
  }, [settingsData]);

  const { control, setValue, handleSubmit, watch, reset } = useForm({
    defaultValues: setFormValues(
      DEFAULT_THEMES_SETTINGS.find((i) => i.themeCode === THEME_BASIC),
      selectedTheme
    ),
  });

  const formValues = watch();

  const onReset = () => {
    if (_.isEmpty(settingsData)) return;

    const themeSettings = festThemes.find((e) => e.themeCode === selectedTheme);
    reset(setFormValues({ ...themeSettings, borderRadius: defaultThemeSettings.borderRadius }));
  };

  useEffect(() => {
    if (_.isEmpty(settingsData)) return;

    let themeSettings = defaultThemeSettings;
    if (selectedTheme !== defaultThemeSettings.themeCode) {
      themeSettings = festThemes.find((e) => e.themeCode === selectedTheme);
    }

    reset(setFormValues({ ...themeSettings, borderRadius: defaultThemeSettings.borderRadius }));
  }, [selectedTheme]);

  const onSubmit = () => {
    dispatch(
      updateSettings(buildSettingsPayload(shop.shop_uuid, settingsData, 'settingPopups.uiThemeSettings', formValues))
    );
    reset(formValues);
  };

  const triggerPowerUser = async () => {
    dispatch(
      triggerPU({
        params: { shopuuid: shop.shop_uuid },
        body: { shop: shop.id },
      })
    );
  };

  const isFormDirty = () => {
    if (!settingPopups.uiThemeSettings) return false;

    const keys = Object.keys(formValues);
    for (const key of keys) {
      let originValue = settingPopups.uiThemeSettings[key];
      if (key === 'themeCode' && !settingPopups.uiThemeSettings[key]) {
        originValue = THEME_BASIC;
      }

      let newValue = formValues[key];
      if (key.toLocaleLowerCase().includes('color')) {
        originValue = originValue?.toLocaleLowerCase();
        newValue = newValue?.toLocaleLowerCase();
      }

      if (newValue !== originValue) return true;
    }

    return false;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        title="Design"
        primaryAction={
          <Button id="btn-save" loading={loading} disabled={!isFormDirty()} submit variant="primary">
            Save
          </Button>
        }
      >
        <div className="IronPop-design">
          <SaveBar open={isFormDirty()} id="design-save-bar">
            <button type="submit" variant="primary" disabled={loading} onClick={onSubmit}></button>
            <button
              type="button"
              disabled={loading}
              onClick={() => {
                setSelectedTheme(settingPopups.uiThemeSettings?.themeCode || THEME_BASIC);
                reset(defaultThemeSettings);
              }}
            ></button>
          </SaveBar>

          <FestiveThemes
            defaultFestiveThemes={festThemes}
            shop={shop}
            selectedTheme={selectedTheme}
            setSelectedTheme={setSelectedTheme}
            watermark={watermark}
            triggerPowerUser={triggerPowerUser}
          />
          <ThemeSettings
            shop={shop}
            watermark={watermark}
            settings={formValues}
            handleReset={onReset}
            control={control}
            setValue={setValue}
          />
        </div>
      </Page>
    </form>
  );
};

export default DesignPage;
