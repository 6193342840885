import { request } from "./base-api";

const productApi = {
  getProducts: (shopuuid, queries) => {
    try {
      return request("admin-api.product.get-products", {
        queries,
        params: { shopuuid },
      });
    } catch (err) {
      return {
        data: [],
        pageInfo: {
          hasNextPage: false,
        },
      };
    }
  },
};

export default productApi;
