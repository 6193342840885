export const DashedCircleIcon = () => {
  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="21px"
      height="21px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1 0 0 1 -1551 -539 )">
        <path
          d="M 1561.5 540.5  C 1566.54 540.5  1570.5 544.46  1570.5 549.5  C 1570.5 554.54  1566.54 558.5  1561.5 558.5  C 1556.46 558.5  1552.5 554.54  1552.5 549.5  C 1552.5 544.46  1556.46 540.5  1561.5 540.5  Z "
          fillRule="nonzero"
          fill="#ffffff"
          stroke="none"
          fillOpacity="0"
        />
        <path
          d="M 1561.5 540  C 1566.82 540  1571 544.18  1571 549.5  C 1571 554.82  1566.82 559  1561.5 559  C 1556.18 559  1552 554.82  1552 549.5  C 1552 544.18  1556.18 540  1561.5 540  Z "
          strokeWidth="2"
          strokeDasharray="6,2"
          stroke="#797979"
          fill="none"
        />
      </g>
    </svg>
  );
};
