import React from "react";
import {
  Card,
  Text,
  RadioButton,
  BlockStack,
  InlineStack,
} from "@shopify/polaris";

import { THEME_BASIC, THEME_LABELS } from "../../common/constants/design";
import SalesNotiPreview from "../../common/components/previews/sale-noti";
import RequestPowerUser from "../power-user/request-pu";

const ThemeSelector = ({
  themes,
  watermark,
  shopType,
  selectedTheme,
  setSelectedTheme,
}) => {
  const handleThemeChange = (value) => {
    setSelectedTheme(value);
  };

  return (
    <div className="radio-button-group">
      {themes.map((theme) => (
        <div key={theme.themeCode} className="radio-button-wrapper">
          <BlockStack gap={300}>
            <SalesNotiPreview
              settings={theme}
              showBrand={
                watermark &&
                shopType === "PU" &&
                theme.themeCode === THEME_BASIC
              }
              size="small"
            />
            <RadioButton
              disabled={shopType !== "PU" && theme.themeCode !== THEME_BASIC}
              label={
                <InlineStack gap={100}>
                  {THEME_LABELS[theme.themeCode]}
                  {theme.themeCode !== THEME_BASIC && (
                    <img
                      alt="ip-crown-icon"
                      src="https://cdn.iron-pop.com/common/crown-icon.png"
                      width={20}
                      height={20}
                    />
                  )}
                </InlineStack>
              }
              checked={selectedTheme === theme.themeCode}
              id={theme.themeCode}
              name="theme"
              onChange={() => handleThemeChange(theme.themeCode)}
              helpText={null}
            />
          </BlockStack>
        </div>
      ))}
    </div>
  );
};

const FestiveThemes = ({
  defaultFestiveThemes,
  shop,
  watermark,
  selectedTheme,
  setSelectedTheme,
  triggerPowerUser,
}) => {
  const { loading, shopPlan = {}, shopInfo = {} } = shop;
  const { shopType = "", reviewShopTypeStatus = "" } = shopPlan;

  const { email = "", customer_email = "" } = shopInfo;
  const shopEmail = email || customer_email || null;

  return (
    <div className="festive-themes">
      <Card>
        <Text as="h2" variant="headingLg" fontWeight="regular">
          Festive themes
        </Text>
        <ThemeSelector
          watermark={watermark}
          themes={defaultFestiveThemes}
          shopType={shopType}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
        />
        <RequestPowerUser
          loading={loading}
          email={shopEmail}
          triggerPowerUser={triggerPowerUser}
          shopType={shopType}
          reviewShopTypeStatus={reviewShopTypeStatus}
        />
      </Card>
    </div>
  );
};
export default FestiveThemes;
