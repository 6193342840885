import { ColorPicker, TextField, BlockStack, Popover, InlineError } from '@shopify/polaris';
import React, { useState, useCallback, useEffect, forwardRef } from 'react';

// Chuyển đổi từ HSV sang HEX
function hsvToHex(h, s, v) {
  let r, g, b;
  let i = Math.floor(h * 6);
  let f = h * 6 - i;
  let p = v * (1 - s);
  let q = v * (1 - f * s);
  let t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
    default:
      break;
  }

  return `#${Math.round(r * 255)
    .toString(16)
    .padStart(2, '0')}${Math.round(g * 255)
    .toString(16)
    .padStart(2, '0')}${Math.round(b * 255)
    .toString(16)
    .padStart(2, '0')}`;
}

// Chuyển đổi từ HEX sang HSV
function hexToHsv(hex) {
  if (hex) {
    let r = parseInt(hex.substring(1, 3), 16) / 255;
    let g = parseInt(hex.substring(3, 5), 16) / 255;
    let b = parseInt(hex.substring(5, 7), 16) / 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let d = max - min;
    let h;
    let s = max === 0 ? 0 : d / max;
    let v = max;

    switch (max) {
      case min:
        h = 0;
        break;
      case r:
        h = (g - b + d * (g < b ? 6 : 0)) / (6 * d);
        break;
      case g:
        h = (b - r + d * 2) / (6 * d);
        break;
      case b:
        h = (r - g + d * 4) / (6 * d);
        break;
      default:
        break;
    }

    return { hue: h * 360, saturation: s, brightness: v };
  }
}

const ColorPickerInput = forwardRef(({ onColorChange, hexColor }, ref) => {
  const [active, setActive] = useState(false);
  const [color, setColor] = useState({
    hue: 120,
    brightness: 1,
    saturation: 1,
  });
  const [hex, setHex] = useState(hexColor ?? hsvToHex(color.hue / 360, color.saturation, color.brightness));
  const [error, setError] = useState(null);

  const handleColorChange = useCallback(
    (newColor) => {
      setColor(newColor);
      setError(null);
      const hexValue = hsvToHex(newColor.hue / 360, newColor.saturation, newColor.brightness);
      setHex(hexValue);
      onColorChange(hexValue);
    },
    [onColorChange]
  );

  const handleHexChange = useCallback(
    (value) => {
      setHex(value);

      if (/^#[0-9A-F]{6}$/i.test(value)) {
        setError(null);
        const hsv = hexToHsv(value);
        setColor({
          hue: hsv.hue,
          saturation: hsv.saturation,
          brightness: hsv.brightness,
        });
        onColorChange(value);
      } else {
        setError('Invalid hex color format');
      }
    },
    [onColorChange]
  );

  const handleChange = useCallback(() => setActive(!active), [active]);

  const activator = (
    <div
      onClick={handleChange}
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: 147,
        padding: '6px 12px',
        border: '1px solid #797979',
        borderRadius: '8px',
      }}
    >
      <div
        style={{
          width: '29px',
          height: '29px',
          backgroundColor: hex,
          marginRight: '10px',
          border: '1px solid #000',
          borderRadius: '6px',
        }}
      />
      <span>{hex}</span>
    </div>
  );
  // Đồng bộ hóa giá trị hex khi color thay đổi
  useEffect(() => {
    setHex(hsvToHex(color.hue / 360, color.saturation, color.brightness));
  }, [color]);

  useEffect(() => {
    if (hexColor) {
      setColor(hexToHsv(hexColor));
    }
  }, [hexColor]);

  return (
    <div>
      <Popover ref={ref} active={active} activator={activator} onClose={handleChange} ariaHaspopup={false}>
        <Popover.Section>
          <BlockStack>
            <ColorPicker onChange={handleColorChange} color={color} />
            <TextField
              label="Color"
              value={hex}
              onChange={handleHexChange}
              type="text"
              error={!!error}
              placeholder="#RRGGBB"
              helpText="Enter color in hex format (e.g., #00FF00)"
            />
            {error && <InlineError message={error} />}
          </BlockStack>
        </Popover.Section>
      </Popover>
    </div>
  );
});

export default ColorPickerInput;
