export const LIST_ITEM_DESKTOP_POSITION = [
  { value: "Bottom Left", label: "Bottom Left" },
  { value: "Bottom Right", label: "Bottom Right" },
  { value: "Top Left", label: "Top Left" },
  { value: "Top Right", label: "Top Right" },
  { value: "Top Center", label: "Top Center" },
  {
    value: "Bottom Center",
    label: "Bottom Center",
  },
];

export const LIST_ITEM_MOBILE_POSITION = [
  { value: "Bottom", label: "Bottom" },
  { value: "Top", label: "Top" },
];
