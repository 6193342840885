import { configureStore } from "@reduxjs/toolkit";
import shopReducer from "./slices/shopSlice";
import settingsReducer from "./slices/settingsSlice";

export const store = configureStore({
  reducer: {
    shop: shopReducer,
    settings: settingsReducer,
  },
});
