import React from "react";
import "./styles.css";

const OrderCountPreview = ({ content, settings, ui }) => {
  const timer =
    settings.format === "hour" ? settings.lookback * 24 : settings.lookback;
  const time = `${timer} ${settings.format === "hour" ? "hours" : "days"}`;

  return (
    <div
      className="order-count-preview-box"
      style={{
        backgroundColor: ui?.backgroundColor ?? "#ffffff",
        color: ui?.textColor ?? "#55586C",
        borderRadius: ui?.borderRadius ?? "2px",
      }}
    >
      <svg
        className="order-count-preview-image"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 805.000000 1200.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)"
          fill={ui?.highlightColor ?? "#ff0000"}
          stroke="none"
        >
          <path
            d="M3565 11835 c188 -442 123 -981 -197 -1619 -210 -420 -434 -763
            -1093 -1676 -557 -770 -785 -1103 -1027 -1490 -616 -988 -967 -1877 -1078
            -2730 -98 -758 -8 -1331 305 -1950 191 -376 448 -717 796 -1055 672 -652 1542
            -1115 2364 -1257 110 -19 167 -22 375 -22 231 -1 252 1 375 27 1153 248 2008
            662 2616 1267 276 276 469 541 639 881 426 848 517 1910 270 3142 -139 694
            -367 1381 -686 2071 -68 148 -69 149 -71 105 -19 -352 -39 -528 -84 -754 -65
            -327 -153 -584 -285 -834 -54 -102 -162 -269 -189 -291 -14 -12 -17 9 -30 230
            -34 559 -191 1226 -448 1910 -449 1196 -1297 2591 -2317 3815 -92 110 -190
            225 -218 255 l-51 55 34 -80z m531 -5962 c414 -465 775 -1031 967 -1518 91
            -232 149 -437 184 -657 23 -146 24 -512 1 -632 l-15 -79 58 6 c121 13 279 88
            436 206 103 78 298 279 401 415 l82 109 0 -106 c0 -510 -177 -1091 -468 -1533
            -131 -199 -368 -455 -547 -591 -282 -213 -612 -378 -966 -482 l-134 -39 -65
            35 c-189 102 -449 301 -635 488 -221 221 -377 437 -506 700 -124 254 -191 488
            -220 764 -15 142 -6 469 16 606 104 650 436 1326 999 2035 134 168 307 370
            317 370 4 0 47 -44 95 -97z"
          ></path>
        </g>
      </svg>
      <div style={{ flexGrow: 1 }}>
        <p
          style={{ color: ui?.highlightColor ?? "#ff0000" }}
          className="order-count-preview-first-line"
        >
          {content?.firstLine?.replace("{{orders}}", "123 orders")}
        </p>
        <p
          style={{ color: ui?.timeColor ?? "#55586C" }}
          className="order-count-preview-product"
        >
          {content?.secondLine?.replace("{{time}}", time)}
        </p>
      </div>
    </div>
  );
};

export default OrderCountPreview;
