export const SETUP_GUIDE = {
  app_embed: {
    title: "Activate the app embed",
    textBtn: "Activate the app embed",
    description: "Activate the app embed to display your sales notification.",
    src: "https://cdn.iron-pop.com/home/setup01.png",
  },
  customize_pop: {
    title: "Customize your sale notifications",
    textBtn: "Customize",
    description:
      "Customize the appearance of notifications to match your preferences.",
    src: "https://cdn.iron-pop.com/home/setup02.png",
  },
  review_app: {
    title: "Your Review Matters ❤️",
    textBtn: "Give Us a Review",
    description:
      "Your review means a lot to us. It motivates us to work hard and make the app even better for you ❤️",
    src: "https://cdn.iron-pop.com/home/setup03.png",
  },
};
