import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { installShop } from "../../stores/slices/shopSlice";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { buildRedirectUrl } from "../../common/helper";
import { Frame } from "@shopify/polaris";
import PageLoading from "../../common/components/route/page-loading";

const Auth = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const shop = useSelector((state) => state.shop);

  useEffect(() => {
    if (shop.status !== "register") {
      const queries = queryString.parse(location.search);
      dispatch(installShop(queries));
    }
  });

  useEffect(() => {
    if (shop.status === "register") {
      const redirectURL = buildRedirectUrl(shop.id);
      window.top.location.href = redirectURL;
    }
  }, [shop.id, shop.status]);

  return (
    <Frame>
      <PageLoading />
    </Frame>
  );
};

export default Auth;
