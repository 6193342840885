import React from "react";
import { Card, EmptyState, Page } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Page>
      <Card roundedAbove="sm">
        <EmptyState
          heading="Page Not Found"
          action={{
            content: "Go to homepage",
            onAction: () => {
              navigate("/");
            },
          }}
          imageContained
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <p>The page you're looking for couldn't be found.</p>
          <p>Check the web address and try again, or go to the home page.</p>
        </EmptyState>
      </Card>
    </Page>
  );
};

export default NotFoundPage;
