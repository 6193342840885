import React from "react";
import { Icon, InlineStack, Tooltip, Text } from "@shopify/polaris";
import { QuestionCircleIcon } from "@shopify/polaris-icons";

const LabelTooltip = ({ label, tooltip }) => {
  return (
    <InlineStack gap={100} blockAlign="center">
      <Text fontWeight="regular" variant="headingMd">
        {label}
      </Text>
      <Tooltip content={tooltip}>
        <Icon source={QuestionCircleIcon} />
      </Tooltip>
    </InlineStack>
  );
};

export default LabelTooltip;
