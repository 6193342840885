export const SPECIFIC_URL_TYPES = [
  {
    value: "equal",
    label: "Simple URL match",
    placeholder: "e.g. https://www.example.com/",
  },
  {
    value: "exact-equal",
    label: "Exact URL match",
    placeholder: "https://www.example.com/?product=X",
  },
  {
    value: "starts-with",
    label: "URL starts with",
    placeholder: "https://www.example.com/collections/",
  },
  { value: "ends-with", label: "URL ends with", placeholder: "/shirt" },
  { value: "contain", label: "URL contains", placeholder: "pants/" },
  { value: "regEx", label: "URL RegEx", placeholder: "VpagesV[^V]+" },
];

export const LIST_ITEM_PAGE_TRIGGER = [
  { label: "On all pages", value: "true" },
  {
    label: "On pages I specify",
    value: "false",
  },
];

export const LIST_ITEM_IMPORT_SOURCE = [
  {
    name: "autoSync",
    id: "sync_orders",
    checkBoxLabel: "Auto - sync data from your shopify orders",
  },
  {
    name: "importOrders",
    id: "import_orders",
    checkBoxLabel: "Import orders",
  },
];

export const NOTIFICATION_ORDER_OPTIONS = [
  {
    value: "newest-first",
    label: "Newest first",
  },
  {
    value: "random-order",
    label: "Random order",
  },
];
