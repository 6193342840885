import { Checkbox, Thumbnail } from "@shopify/polaris";

// DONE
const OptionItem = (props) => {
  const { item, index, onSelect, isFull = false } = props;
  const { image, id = "" } = item;

  return (
    <li className="product-item" key={`product-id-${id}`}>
      {/* Selected */}
      <div className="BC-ProductItem__Checkbox">
        <Checkbox
          checked={item.isSelected}
          onChange={() => onSelect(index)}
          disabled={isFull && !item.isSelected}
        />
      </div>

      {/* Product Image */}
      <div className={`product-image ${image ? "" : "no-img"}`}>
        <Thumbnail
          source={image ? image : "https://cdn.iron-pop.com/common/no-img.jpg"}
          size="small"
        />
      </div>

      {/* Product title */}
      <div>{item.title}</div>
    </li>
  );
};
export default OptionItem;
